@use "@scss/global.scss" as *;

.col {
  flex-direction: column;
}
.row {
  flex-direction: row;
}
.flex-wrap {
  max-width: 100%;
  @include flex-wrap;
}

.flex-1 {
  flex: 1;
}

.overflow-n {
  overflow: hidden;
}

.overflow-x-n {
  overflow-x: hidden;
}

.overflow-y-n {
  overflow-y: hidden;
}

.thin {
  font-weight: $thin !important;
}
.extra-light {
  font-weight: $extra-light !important;
}
.light {
  font-weight: $light !important;
}
.regular {
  font-weight: $regular !important;
}
.medium {
  font-weight: $medium !important;
}
.semi-bold {
  font-weight: $semi-bold !important;
}
.bold {
  font-weight: $bold !important;
}
.extra-bold {
  font-weight: $extra-bold !important;
}
.black-bold {
  font-weight: $black-bold !important;
}

.uppercase {
  text-transform: uppercase;
}

.capitalize {
  &::first-letter {
    text-transform: capitalize;
  }
}

.capitalize-each {
  text-transform: capitalize;
}

.underline {
  text-decoration: underline;
  text-decoration-color: currentColor;
}

.border-bottom {
  border-bottom: 3px solid currentColor;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.absolute-center {
  @include absolute-center();
}

.relative-center {
  position: relative;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.relative-center-x {
  position: relative;
  left: 50%;
  translate: -50%;
}

.relative-center-y {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.t-c {
  text-align: center;
}

.ellipsis {
  word-wrap: break-word;
  @include ellipsis-after-lines(1);
}

.pointer {
  cursor: pointer;
}

.none-events {
  pointer-events: none;
}

.op-0 {
  opacity: 0;
}
.op-01 {
  opacity: 0.1;
}
.op-02 {
  opacity: 0.2;
}
.op-03 {
  opacity: 0.3;
}
.op-04 {
  opacity: 0.4;
}
.op-05 {
  opacity: 0.5;
}
.op-06 {
  opacity: 0.6;
}
.op-07 {
  opacity: 0.7;
}
.op-08 {
  opacity: 0.8;
}
.op-09 {
  opacity: 0.9;
}
.op-10 {
  opacity: 1;
}

.white-0 {
  color: $white-0;
}
.bg-white-0 {
  background: $white-0;
}

.white-black-1 {
  color: $white-black-1;
}
.bg-white-black-1 {
  background: $white-black-1;
}

.white-black-2 {
  color: $white-black-2;
}
.bg-white-black-2 {
  background: $white-black-2;
}

.white-black-3 {
  color: $white-black-3;
}
.bg-white-black-3 {
  background: $white-black-3;
}

.white-black-4 {
  color: $white-black-4;
}
.bg-white-black-4 {
  background: $white-black-4;
}

.white-black-5 {
  color: $white-black-5;
}
.bg-white-black-5 {
  background: $white-black-5;
}

.white-black-6 {
  color: $white-black-6;
}
.bg-white-black-6 {
  background: $white-black-6;
}

.white-black-7 {
  color: $white-black-7;
}
.bg-white-black-7 {
  background: $white-black-7;
}

.white-black-8 {
  color: $white-black-8;
}
.bg-white-black-8 {
  background: $white-black-8;
}
.white-black-9 {
  color: $white-black-9;
}
.bg-white-black-9 {
  background: $white-black-9;
}

.white-black-10 {
  color: $white-black-10;
}
.bg-white-black-10 {
  background: $white-black-10;
}

.white-black-11 {
  color: $white-black-11;
}
.bg-white-black-11 {
  background: $white-black-11;
}

.white-black-12 {
  color: $white-black-12;
}
.bg-white-black-12 {
  background: $white-black-12;
}

.white-black-13 {
  color: $white-black-13;
}
.bg-white-black-13 {
  background: $white-black-13;
}

.black-0 {
  color: $black-0;
}
.bg-black-0 {
  background: $black-0;
}

.blue-1 {
  color: $blue-1;
}
.bg-blue-1 {
  background-color: $blue-1;
}
.blue-2 {
  color: $blue-2;
}
.bg-blue-2 {
  background-color: $blue-2;
}
.blue-3 {
  color: $blue-3;
}
.bg-blue-3 {
  background-color: $blue-3;
}
.blue-4 {
  color: $blue-4;
}
.bg-blue-4 {
  background-color: $blue-4;
}
.blue-5 {
  color: $blue-5;
}
.bg-blue-5 {
  background-color: $blue-5;
}
.blue-6 {
  color: $blue-6;
}
.bg-blue-6 {
  background-color: $blue-6;
}
.blue-7 {
  color: $blue-7;
}
.bg-blue-7 {
  background-color: $blue-7;
}
.blue-8 {
  color: $blue-8;
}
.bg-blue-8 {
  background-color: $blue-8;
}
.blue-9 {
  color: $blue-9;
}
.bg-blue-9 {
  background-color: $blue-9;
}
.blue-10 {
  color: $blue-10;
}
.bg-blue-10 {
  background-color: $blue-10;
}
.blue-11 {
  color: $blue-11;
}
.bg-blue-11 {
  background-color: $blue-11;
}
.blue-12 {
  color: $blue-12;
}
.bg-blue-12 {
  background-color: $blue-12;
}
.blue-13 {
  color: $blue-13;
}
.bg-blue-13 {
  background-color: $blue-13;
}
.blue-14 {
  color: $blue-14;
}
.bg-blue-14 {
  background-color: $blue-14;
}
.blue-15 {
  color: $blue-15;
}
.bg-blue-15 {
  background-color: $blue-15;
}

.primary {
  color: $primary;
}
.bg-primary {
  background: $primary;
}

.secondary {
  color: $secondary;
}
.bg-secondary {
  background: $secondary;
}

.tertiary {
  color: $tertiary;
}
.bg-tertiary {
  background: $tertiary;
}

.success {
  color: $success;
}
.bg-success {
  background: $success;
}

.danger {
  color: $danger;
}
.bg-danger {
  background: $danger;
}

.warning {
  color: $warning;
}
.bg-warning {
  background: $warning;
}

.info {
  color: $info;
}
.bg-info {
  background: $info;
}

.text-shadow-1 {
  text-shadow: text-shadow(1px, #fff);
}
.text-shadow-2 {
  text-shadow: text-shadow(2px, #fff);
}
.text-shadow-3 {
  text-shadow: text-shadow(3px, #fff);
}
.text-shadow-4 {
  text-shadow: text-shadow(4px, #fff);
}
.text-shadow-5 {
  text-shadow: text-shadow(5px, #fff);
}
.text-shadow-6 {
  text-shadow: text-shadow(6px, #fff);
}
.text-shadow-7 {
  text-shadow: text-shadow(7px, #fff);
}
.text-shadow-8 {
  text-shadow: text-shadow(8px, #fff);
}
.text-shadow-9 {
  text-shadow: text-shadow(9px, #fff);
}
.text-shadow-10 {
  text-shadow: text-shadow(10px, #fff);
}

// @each $bp-key, $bp-val in $breakpoints {
//   @each $wv-key, $wv-val in $width-variants {
//     @include mq-min($bp-val) {
//       .w-#{$bp-key}-#{$wv-key} {
//         width: $wv-val;
//       }
//       .h-#{$bp-key}-#{$wv-key} {
//         height: $wv-val;
//       }
//     }
//   }
// }

// @each $bp-key, $bp-val in $breakpoints {
//   @each $pv-key, $pv-val in $position-variants {
//     @include mq-min($bp-val) {
//       .flex-#{$bp-key}-#{$pv-key} {
//         align-items: $pv-val;
//       }
//     }
//   }
// }

// @each $bp-key, $bp-val in $breakpoints {
//   @each $tr-key, $tr-val in $text-responsive {
//     @include mq-min($bp-val) {
//       .t-#{$bp-key}-#{$tr-key} *,
//       .t-#{$bp-key}-#{$tr-key} {
//         text-align: $tr-val;
//       }
//     }
//   }
// }

// @each $bp-key, $bp-val in $breakpoints {
//   @each $displ-key, $displ-val in $display-variants {
//     @include mq-min($bp-val) {
//       .d-#{$bp-key}-#{$displ-key} {
//         display: $displ-val;
//       }
//     }
//   }
// }

// @each $bp-key, $bp-val in $breakpoints {
//   @each $grid-key, $grid-val in $grid-values {
//     @include mq-min($bp-val) {
//       .#{$bp-key}-#{$grid-key} {
//         width: $grid-val;
//       }

//       .off-#{$bp-key}-#{$grid-key} {
//         margin-left: $grid-val;
//       }
//     }
//   }
// }

// @each $bp-key, $bp-val in $breakpoints {
//   @each $sv-key, $sv-val in $size-variants {
//     @each $mp-key, $mp-val in $margins-paddings {
//       @include mq-min($bp-val) {
//         .#{$mp-key}-#{$bp-key}-#{$sv-key} {
//           #{$mp-val}: $sv-val;
//         }
//       }
//     }
//   }
// }

// @each $bp-key, $bp-val in $breakpoints {
//   @each $sv-key, $sv-val in $size-variants {
//     @include mq-min($bp-val) {
//       .m-#{$bp-key}-a {
//         margin: auto;
//       }
//     }
//   }
// }

// @each $bp-key, $bp-val in $breakpoints {
//   @each $sv-key, $sv-val in $size-variants {
//     @include mq-min($bp-val) {
//       .rg-#{$bp-key}-#{$sv-key} {
//         row-gap: $sv-val;
//       }

//       .cg-#{$bp-key}-#{$sv-key} {
//         column-gap: $sv-val;
//       }
//     }
//   }
// }
// @each $bp-key, $bp-val in $breakpoints {
//   @each $ov-key in $order-variants {
//     @include mq-min($bp-val) {
//       .or-#{$bp-key}-#{$ov-key} {
//         order: $ov-key;
//       }
//     }
//   }
// }

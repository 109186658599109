@use "./src/scss/global.scss" as *;

.timer {
  width: 860px;

  h1 {
    font-weight: 600;
  }

  @include mq-max($lg) {
    width: 100%;
    h1 {
      font-size: 42px;
    }
  }
}

//------------------COLORS------------------
//--------------MESSAGES--------------
$success: #36bd2b;
$danger: #e74a3b;
$warning: #e6ce47;
$info: #016fd0;
//--------------MESSAGES--------------

$white-0: #ffffff;

//WHITE_BLACKS
$white-black-1: #fcfcfd;
$white-black-2: #eeeff1;
$white-black-3: #e0e2e6;
$white-black-4: #d2d5da;
$white-black-5: #c4c8cf;
$white-black-6: #a8adb8;
$white-black-7: #8c93a1;
$white-black-8: #70798a;
$white-black-9: #5a616e;
$white-black-10: #434953;
$white-black-11: #2d3037;
$white-black-12: #222429;
$white-black-13: #16181c;
//WHITE_BLACKS

//BLUE
$blue-1: #fdfdff;
$blue-2: #e8effe;
$blue-3: #d3e0fc;
$blue-4: #bed2fb;
$blue-5: #a9c4f9;
$blue-6: #7ea7f7;
$blue-7: #548bf4;
$blue-8: #2a6ef1;
$blue-9: #225ccc;
$blue-10: #1b4aa7;
$blue-11: #133981;
$blue-12: #0f306f;
$blue-13: #0c275c;
$blue-14: #041537;
$blue-15: #040b18;
//BLUE

$uniswap: #ff007a;
$black-0: #000000;

$primary: #f0f0f0;
$secondary: #f0f0f0;
$tertiary: #f0f0f0;

//------------------COLORS------------------

// BREAKPOINTS
$xs: 0px;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1400px;
//BREAKPOINTS

// RESPONSIVE
$size-dx: 8px;

$size-1: $size-dx;
$size-2: $size-dx * 2;
$size-3: $size-dx * 3;
$size-4: $size-dx * 4;
$size-5: $size-dx * 5;
$size-6: $size-dx * 6;
$size-7: $size-dx * 7;
$size-8: $size-dx * 8;
$size-9: $size-dx * 9;
$size-10: $size-dx * 10;
$size-11: $size-dx * 11;
$size-12: $size-dx * 12;
$size-13: $size-dx * 13;
$size-14: $size-dx * 14;
$size-15: $size-dx * 15;
$size-16: $size-dx * 16;
$size-17: $size-dx * 17;
$size-18: $size-dx * 18;
$size-19: $size-dx * 19;
$size-20: $size-dx * 20;
// RESPONSIVE

$bs--1: inset 0px 0.5px 4px rgba(96, 97, 112, 0.32);
$bs-0: 0px 0px 0px rgba(0, 0, 0, 0);
$bs-2: 0px 0px 1px rgba(40, 41, 61, 0.04), 0px 2px 4px rgba(96, 97, 112, 0.16);
$bs-3: 0px 0px 2px rgba(40, 41, 61, 0.04), 0px 4px 8px rgba(96, 97, 112, 0.16);
$bs-4: 0px 2px 4px rgba(40, 41, 61, 0.04), 0px 8px 16px rgba(96, 97, 112, 0.16);
$bs-5: 0px 2px 8px rgba(40, 41, 61, 0.04), 0px 16px 24px rgba(96, 97, 112, 0.16);
$bs-6: 0px 2px 8px rgba(40, 41, 61, 0.08), 0px 20px 32px rgba(96, 97, 112, 0.24);
$bs-7: 0px 0.5px 2px 0px rgba(96, 97, 112, 0.16), 0px 0px 1px 0px rgba(40, 41, 61, 0.08);

$bs-1: 8px 8px 20px 0px rgba(220, 220, 220, 0.9), -8px -8px 16px 0px rgba(255, 255, 255, 0.9),
  8px -8px 16px 0px rgba(220, 220, 220, 0.2), -8px 8px 16px 0px rgba(220, 220, 220, 0.2),
  -1px -1px 2px 0px rgba(220, 220, 220, 0.5) inset, 1px 1px 2px 0px rgba(255, 255, 255, 0.3) inset;

$ds-1: drop-shadow(3px 3px 20px rgba(36, 65, 93, 0.3));

$gradient-1: linear-gradient(135deg, #fff 0%, #e8e8e8 100%);

$fs-d1: 148px;
$fs-d2: 110px;
$fs-1: 96px;
$fs-2: 64px;
$fs-3: 42px;
$fs-4: 32px;
$fs-5: 22px;
$fs-6: 16px;
$fs-7: 14px;
$fs-8: 12px;

$mfs-d1: 64px;
$mfs-d2: 52px;
$mfs-1: 40px;
$mfs-2: 32px;
$mfs-3: 26px;
$mfs-4: 22px;
$mfs-5: 20px;
$mfs-6: 16px;
$mfs-7: 14px;
$mfs-8: 12px;

$lh-1: 78%;
$lh-2: 95%;
$lh-3: 120%;
$lh-4: 130%;
$lh-5: 135%;
$lh-6: 145%;
$lh-7: 150%;

$ls-1: -0.07em;
$ls-2: -0.06em;
$ls-3: -0.05em;
$ls-4: -0.03em;
$ls-5: -0.01em;
$ls-6: normal;
$ls-7: 0.01em;
$ls-8: 0.02em;
$ls-9: 0.03em;

$thin: 100;
$extra-light: 200;
$light: 300;
$regular: 400;
$medium: 500;
$semi-bold: 600;
$bold: 700;
$extra-bold: 800;
$black-bold: 900;

@use "@scss/global.scss" as *;

.mobile-menu-content {
  width: 100%;
  height: max-content;

  overflow-y: auto;

  padding: 0px $size-2;
  padding-top: $navbar-height-mobile;
  padding-bottom: $size-8;
}

@use "@scss/global.scss" as *;

.steps-button {
  height: 70px;
  background: #2a6ef1;
  border-radius: 16px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  &:disabled {
    cursor: default;
    opacity: 0.4;
    // filter: saturate(0);
  }
}

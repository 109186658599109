@use "@scss/global.scss" as *;

.wrap {
  .fugazi-button {
    border-width: 3px;
    border-style: solid;
    border-radius: inherit;
    width: 360px;
    max-width: 100%;

    height: 80px;

    @include mq-max($lg) {
      width: 100%;
    }

    padding: 18px 32px;

    background: $white-0;

    > * {
      color: $white-black-13;
    }

    transition: border-width 0.15s ease-in-out, margin 0.15s ease-in-out;

    &.color {
      &-green {
        border-color: $success;
        &.disabled {
          border-color: $white-black-4;
        }
      }
      &-uniswap {
        border-color: $uniswap;
      }
      &-secondary {
      }
      &-tertiary {
      }
    }

    &.hierarchy {
      &-primary {
      }
      &-secondary {
      }
      &-tertiary {
      }
    }

    &.disabled {
      border-width: 0px;
      background-color: $blue-2;
      cursor: default;
      > * {
        opacity: 0.6;
      }
    }

    &:not(.disabled):hover {
      border-width: 6px;
    }
  }
}

@use "@scss/global.scss" as *;

.fugazi-card {
  height: max-content;
  width: 100%;
  max-width: 358px;
  padding: 12px;
  border-radius: 24px;
  background-color: white;

  box-shadow: 0px 114.75px 91.8px 0px rgba(0, 0, 0, 0.07), 0px 74.375px 53.763px 0px rgba(0, 0, 0, 0.05),
    0px 44.2px 29.24px 0px rgba(0, 0, 0, 0.04), 0px 22.95px 14.918px 0px rgba(0, 0, 0, 0.04),
    0px 9.35px 7.48px 0px rgba(0, 0, 0, 0.03), 0px 2.125px 3.613px 0px rgba(0, 0, 0, 0.02);

  .img {
    width: 100%;
  }

  .title {
    max-width: 300px;
  }

  .description {
    min-height: 150px;
  }

  &.mobile {
    .description {
      min-height: unset;
    }
  }
}

@use "./variables.scss" as *;

@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin flex-row($direction) {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: $direction;
}

@mixin flex-column($v-direction, $h-direction: center) {
  display: flex;
  flex-direction: column;
  align-items: $v-direction;
  justify-content: $h-direction;
}

@mixin flex-wrap {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

@mixin ellipsis-after-lines($lines: 1) {
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin absolute-center() {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

@mixin relative-center() {
  position: relative;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

@mixin mq-min($size) {
  @media (min-width: $size) {
    @content;
  }
}

@mixin mq-max($size) {
  @media (max-width: $size) {
    @content;
  }
}

@mixin spread-map($map: ()) {
  @each $key, $value in $map {
    #{$key}: $value;
  }
}

@function theme-var($key) {
  @if not map-has-key($theme-map-light, $key) {
    @error "key: '#{$key}', is not a key in map: #{$theme-map-light}";
  }
  @return var($key);
}

@mixin basePadding-x {
  padding-left: $size-3;
  padding-right: $size-3;

  @include mq-max($md) {
    padding-left: $size-2;
    padding-right: $size-2;
  }
}

@mixin text($fs, $fw, $ls, $lh) {
  font-size: $fs;
  font-weight: $fw;
  letter-spacing: $ls;
  line-height: $lh;
}

@mixin d1 {
  @include text($fs-d1, $bold, $ls-1, $lh-1);
}
@mixin md1 {
  @include text($mfs-d1, $bold, $ls-1, $lh-1);
}

@mixin d2 {
  @include text($fs-d2, $bold, $ls-1, $lh-2);
}
@mixin md2 {
  @include text($mfs-d2, $bold, $ls-1, $lh-2);
}

@mixin h1 {
  @include text($fs-1, $semi-bold, $ls-2, $lh-2);
}
@mixin mh1 {
  @include text($mfs-1, $semi-bold, $ls-2, $lh-2);
}

@mixin h2 {
  @include text($fs-2, $regular, $ls-3, $lh-2);
}
@mixin mh2 {
  @include text($mfs-2, $regular, $ls-3, $lh-3);
}

@mixin h3 {
  @include text($fs-3, $regular, $ls-4, $lh-4);
}
@mixin mh3 {
  @include text($mfs-3, $regular, $ls-4, $lh-4);
}

@mixin h4 {
  @include text($fs-4, $semi-bold, $ls-4, $lh-4);
}
@mixin mh4 {
  @include text($mfs-4, $semi-bold, $ls-4, $lh-4);
}

@mixin h5 {
  @include text($fs-5, $regular, $ls-5, $lh-5);
}
@mixin mh5 {
  @include text($mfs-5, $regular, $ls-5, $lh-5);
}

@mixin p1 {
  @include text($fs-6, $regular, $ls-6, $lh-6);
}

@mixin p2 {
  @include text($fs-7, $regular, $ls-8, $lh-7);
}

@mixin p3 {
  @include text($fs-8, $regular, $ls-7, $lh-7);
}

@use "@scss/global.scss" as *;

.tokenomics {
  overflow-x: hidden;
  @include mq-max($lg) {
    h1 {
      font-size: 42px;
    }
  }
}

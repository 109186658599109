@use "@scss/global.scss" as *;

.battle-bg {
  // background-color: $blue-2;
}

.battle {
  padding: 100px 0px;
  width: 100%;
}

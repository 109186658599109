@use "@scss/global.scss" as *;

.menu {
  position: relative;

  min-height: max-content;
  height: 100vh;
  width: 100%;
  background-color: $blue-14;

  position: fixed;
  left: 0px;
  top: 0px;

  z-index: 13;

  animation-duration: 0.25s;
  animation-timing-function: cubic-bezier(0.44, 0.02, 0.43, 1);
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
  animation-direction: normal;

  transform: translateY(-100%);

  &.animations {
    &.show {
      animation-name: slide-down;
    }
    &.hide {
      animation-name: slide-up;
    }
  }
}

@media (min-width: 0px) {
  .w-xs-u {
    width: unset;
  }
  .h-xs-u {
    height: unset;
  }
  .w-xs-mc {
    width: max-content;
  }
  .h-xs-mc {
    height: max-content;
  }
  .w-xs-100 {
    width: 100%;
  }
  .h-xs-100 {
    height: 100%;
  }
  .w-xs-a {
    width: auto;
  }
  .h-xs-a {
    height: auto;
  }
}
@media (min-width: 576px) {
  .w-sm-u {
    width: unset;
  }
  .h-sm-u {
    height: unset;
  }
  .w-sm-mc {
    width: max-content;
  }
  .h-sm-mc {
    height: max-content;
  }
  .w-sm-100 {
    width: 100%;
  }
  .h-sm-100 {
    height: 100%;
  }
  .w-sm-a {
    width: auto;
  }
  .h-sm-a {
    height: auto;
  }
}
@media (min-width: 768px) {
  .w-md-u {
    width: unset;
  }
  .h-md-u {
    height: unset;
  }
  .w-md-mc {
    width: max-content;
  }
  .h-md-mc {
    height: max-content;
  }
  .w-md-100 {
    width: 100%;
  }
  .h-md-100 {
    height: 100%;
  }
  .w-md-a {
    width: auto;
  }
  .h-md-a {
    height: auto;
  }
}
@media (min-width: 992px) {
  .w-lg-u {
    width: unset;
  }
  .h-lg-u {
    height: unset;
  }
  .w-lg-mc {
    width: max-content;
  }
  .h-lg-mc {
    height: max-content;
  }
  .w-lg-100 {
    width: 100%;
  }
  .h-lg-100 {
    height: 100%;
  }
  .w-lg-a {
    width: auto;
  }
  .h-lg-a {
    height: auto;
  }
}
@media (min-width: 1200px) {
  .w-xl-u {
    width: unset;
  }
  .h-xl-u {
    height: unset;
  }
  .w-xl-mc {
    width: max-content;
  }
  .h-xl-mc {
    height: max-content;
  }
  .w-xl-100 {
    width: 100%;
  }
  .h-xl-100 {
    height: 100%;
  }
  .w-xl-a {
    width: auto;
  }
  .h-xl-a {
    height: auto;
  }
}
@media (min-width: 1400px) {
  .w-xxl-u {
    width: unset;
  }
  .h-xxl-u {
    height: unset;
  }
  .w-xxl-mc {
    width: max-content;
  }
  .h-xxl-mc {
    height: max-content;
  }
  .w-xxl-100 {
    width: 100%;
  }
  .h-xxl-100 {
    height: 100%;
  }
  .w-xxl-a {
    width: auto;
  }
  .h-xxl-a {
    height: auto;
  }
}
@media (min-width: 0px) {
  .flex-xs-l {
    align-items: flex-start;
  }
  .flex-xs-c {
    align-items: center;
  }
  .flex-xs-r {
    align-items: flex-end;
  }
}
@media (min-width: 576px) {
  .flex-sm-l {
    align-items: flex-start;
  }
  .flex-sm-c {
    align-items: center;
  }
  .flex-sm-r {
    align-items: flex-end;
  }
}
@media (min-width: 768px) {
  .flex-md-l {
    align-items: flex-start;
  }
  .flex-md-c {
    align-items: center;
  }
  .flex-md-r {
    align-items: flex-end;
  }
}
@media (min-width: 992px) {
  .flex-lg-l {
    align-items: flex-start;
  }
  .flex-lg-c {
    align-items: center;
  }
  .flex-lg-r {
    align-items: flex-end;
  }
}
@media (min-width: 1200px) {
  .flex-xl-l {
    align-items: flex-start;
  }
  .flex-xl-c {
    align-items: center;
  }
  .flex-xl-r {
    align-items: flex-end;
  }
}
@media (min-width: 1400px) {
  .flex-xxl-l {
    align-items: flex-start;
  }
  .flex-xxl-c {
    align-items: center;
  }
  .flex-xxl-r {
    align-items: flex-end;
  }
}
@media (min-width: 0px) {
  .dir-xs-row {
    flex-direction: row;
  }
  .dir-xs-row-r {
    flex-direction: row-reverse;
  }
  .dir-xs-col {
    flex-direction: column;
  }
  .dir-xs-col-r {
    flex-direction: column-reverse;
  }
}
@media (min-width: 576px) {
  .dir-sm-row {
    flex-direction: row;
  }
  .dir-sm-row-r {
    flex-direction: row-reverse;
  }
  .dir-sm-col {
    flex-direction: column;
  }
  .dir-sm-col-r {
    flex-direction: column-reverse;
  }
}
@media (min-width: 768px) {
  .dir-md-row {
    flex-direction: row;
  }
  .dir-md-row-r {
    flex-direction: row-reverse;
  }
  .dir-md-col {
    flex-direction: column;
  }
  .dir-md-col-r {
    flex-direction: column-reverse;
  }
}
@media (min-width: 992px) {
  .dir-lg-row {
    flex-direction: row;
  }
  .dir-lg-row-r {
    flex-direction: row-reverse;
  }
  .dir-lg-col {
    flex-direction: column;
  }
  .dir-lg-col-r {
    flex-direction: column-reverse;
  }
}
@media (min-width: 1200px) {
  .dir-xl-row {
    flex-direction: row;
  }
  .dir-xl-row-r {
    flex-direction: row-reverse;
  }
  .dir-xl-col {
    flex-direction: column;
  }
  .dir-xl-col-r {
    flex-direction: column-reverse;
  }
}
@media (min-width: 1400px) {
  .dir-xxl-row {
    flex-direction: row;
  }
  .dir-xxl-row-r {
    flex-direction: row-reverse;
  }
  .dir-xxl-col {
    flex-direction: column;
  }
  .dir-xxl-col-r {
    flex-direction: column-reverse;
  }
}
@media (min-width: 0px) {
  .t-xs-l,
  .t-xs-l * {
    text-align: start;
  }
  .t-xs-c,
  .t-xs-c * {
    text-align: center;
  }
  .t-xs-r,
  .t-xs-r * {
    text-align: end;
  }
}
@media (min-width: 576px) {
  .t-sm-l,
  .t-sm-l * {
    text-align: start;
  }
  .t-sm-c,
  .t-sm-c * {
    text-align: center;
  }
  .t-sm-r,
  .t-sm-r * {
    text-align: end;
  }
}
@media (min-width: 768px) {
  .t-md-l,
  .t-md-l * {
    text-align: start;
  }
  .t-md-c,
  .t-md-c * {
    text-align: center;
  }
  .t-md-r,
  .t-md-r * {
    text-align: end;
  }
}
@media (min-width: 992px) {
  .t-lg-l,
  .t-lg-l * {
    text-align: start;
  }
  .t-lg-c,
  .t-lg-c * {
    text-align: center;
  }
  .t-lg-r,
  .t-lg-r * {
    text-align: end;
  }
}
@media (min-width: 1200px) {
  .t-xl-l,
  .t-xl-l * {
    text-align: start;
  }
  .t-xl-c,
  .t-xl-c * {
    text-align: center;
  }
  .t-xl-r,
  .t-xl-r * {
    text-align: end;
  }
}
@media (min-width: 1400px) {
  .t-xxl-l,
  .t-xxl-l * {
    text-align: start;
  }
  .t-xxl-c,
  .t-xxl-c * {
    text-align: center;
  }
  .t-xxl-r,
  .t-xxl-r * {
    text-align: end;
  }
}
@media (min-width: 0px) {
  .d-xs-b {
    display: block;
  }
  .d-xs-f {
    display: flex;
  }
  .d-xs-n {
    display: none;
  }
}
@media (min-width: 576px) {
  .d-sm-b {
    display: block;
  }
  .d-sm-f {
    display: flex;
  }
  .d-sm-n {
    display: none;
  }
}
@media (min-width: 768px) {
  .d-md-b {
    display: block;
  }
  .d-md-f {
    display: flex;
  }
  .d-md-n {
    display: none;
  }
}
@media (min-width: 992px) {
  .d-lg-b {
    display: block;
  }
  .d-lg-f {
    display: flex;
  }
  .d-lg-n {
    display: none;
  }
}
@media (min-width: 1200px) {
  .d-xl-b {
    display: block;
  }
  .d-xl-f {
    display: flex;
  }
  .d-xl-n {
    display: none;
  }
}
@media (min-width: 1400px) {
  .d-xxl-b {
    display: block;
  }
  .d-xxl-f {
    display: flex;
  }
  .d-xxl-n {
    display: none;
  }
}
@media (min-width: 0px) {
  .m-xs-0 {
    margin: 0;
  }
  .mt-xs-0 {
    margin-top: 0;
  }
  .mr-xs-0 {
    margin-right: 0;
  }
  .mb-xs-0 {
    margin-bottom: 0;
  }
  .ml-xs-0 {
    margin-left: 0;
  }
  .p-xs-0 {
    padding: 0;
  }
  .pt-xs-0 {
    padding-top: 0;
  }
  .pr-xs-0 {
    padding-right: 0;
  }
  .pb-xs-0 {
    padding-bottom: 0;
  }
  .pl-xs-0 {
    padding-left: 0;
  }
  .m-xs-1 {
    margin: 8px;
  }
  .mt-xs-1 {
    margin-top: 8px;
  }
  .mr-xs-1 {
    margin-right: 8px;
  }
  .mb-xs-1 {
    margin-bottom: 8px;
  }
  .ml-xs-1 {
    margin-left: 8px;
  }
  .p-xs-1 {
    padding: 8px;
  }
  .pt-xs-1 {
    padding-top: 8px;
  }
  .pr-xs-1 {
    padding-right: 8px;
  }
  .pb-xs-1 {
    padding-bottom: 8px;
  }
  .pl-xs-1 {
    padding-left: 8px;
  }
  .m-xs-2 {
    margin: 16px;
  }
  .mt-xs-2 {
    margin-top: 16px;
  }
  .mr-xs-2 {
    margin-right: 16px;
  }
  .mb-xs-2 {
    margin-bottom: 16px;
  }
  .ml-xs-2 {
    margin-left: 16px;
  }
  .p-xs-2 {
    padding: 16px;
  }
  .pt-xs-2 {
    padding-top: 16px;
  }
  .pr-xs-2 {
    padding-right: 16px;
  }
  .pb-xs-2 {
    padding-bottom: 16px;
  }
  .pl-xs-2 {
    padding-left: 16px;
  }
  .m-xs-3 {
    margin: 24px;
  }
  .mt-xs-3 {
    margin-top: 24px;
  }
  .mr-xs-3 {
    margin-right: 24px;
  }
  .mb-xs-3 {
    margin-bottom: 24px;
  }
  .ml-xs-3 {
    margin-left: 24px;
  }
  .p-xs-3 {
    padding: 24px;
  }
  .pt-xs-3 {
    padding-top: 24px;
  }
  .pr-xs-3 {
    padding-right: 24px;
  }
  .pb-xs-3 {
    padding-bottom: 24px;
  }
  .pl-xs-3 {
    padding-left: 24px;
  }
  .m-xs-4 {
    margin: 32px;
  }
  .mt-xs-4 {
    margin-top: 32px;
  }
  .mr-xs-4 {
    margin-right: 32px;
  }
  .mb-xs-4 {
    margin-bottom: 32px;
  }
  .ml-xs-4 {
    margin-left: 32px;
  }
  .p-xs-4 {
    padding: 32px;
  }
  .pt-xs-4 {
    padding-top: 32px;
  }
  .pr-xs-4 {
    padding-right: 32px;
  }
  .pb-xs-4 {
    padding-bottom: 32px;
  }
  .pl-xs-4 {
    padding-left: 32px;
  }
  .m-xs-5 {
    margin: 40px;
  }
  .mt-xs-5 {
    margin-top: 40px;
  }
  .mr-xs-5 {
    margin-right: 40px;
  }
  .mb-xs-5 {
    margin-bottom: 40px;
  }
  .ml-xs-5 {
    margin-left: 40px;
  }
  .p-xs-5 {
    padding: 40px;
  }
  .pt-xs-5 {
    padding-top: 40px;
  }
  .pr-xs-5 {
    padding-right: 40px;
  }
  .pb-xs-5 {
    padding-bottom: 40px;
  }
  .pl-xs-5 {
    padding-left: 40px;
  }
  .m-xs-6 {
    margin: 48px;
  }
  .mt-xs-6 {
    margin-top: 48px;
  }
  .mr-xs-6 {
    margin-right: 48px;
  }
  .mb-xs-6 {
    margin-bottom: 48px;
  }
  .ml-xs-6 {
    margin-left: 48px;
  }
  .p-xs-6 {
    padding: 48px;
  }
  .pt-xs-6 {
    padding-top: 48px;
  }
  .pr-xs-6 {
    padding-right: 48px;
  }
  .pb-xs-6 {
    padding-bottom: 48px;
  }
  .pl-xs-6 {
    padding-left: 48px;
  }
  .m-xs-7 {
    margin: 56px;
  }
  .mt-xs-7 {
    margin-top: 56px;
  }
  .mr-xs-7 {
    margin-right: 56px;
  }
  .mb-xs-7 {
    margin-bottom: 56px;
  }
  .ml-xs-7 {
    margin-left: 56px;
  }
  .p-xs-7 {
    padding: 56px;
  }
  .pt-xs-7 {
    padding-top: 56px;
  }
  .pr-xs-7 {
    padding-right: 56px;
  }
  .pb-xs-7 {
    padding-bottom: 56px;
  }
  .pl-xs-7 {
    padding-left: 56px;
  }
  .m-xs-8 {
    margin: 64px;
  }
  .mt-xs-8 {
    margin-top: 64px;
  }
  .mr-xs-8 {
    margin-right: 64px;
  }
  .mb-xs-8 {
    margin-bottom: 64px;
  }
  .ml-xs-8 {
    margin-left: 64px;
  }
  .p-xs-8 {
    padding: 64px;
  }
  .pt-xs-8 {
    padding-top: 64px;
  }
  .pr-xs-8 {
    padding-right: 64px;
  }
  .pb-xs-8 {
    padding-bottom: 64px;
  }
  .pl-xs-8 {
    padding-left: 64px;
  }
  .m-xs-9 {
    margin: 72px;
  }
  .mt-xs-9 {
    margin-top: 72px;
  }
  .mr-xs-9 {
    margin-right: 72px;
  }
  .mb-xs-9 {
    margin-bottom: 72px;
  }
  .ml-xs-9 {
    margin-left: 72px;
  }
  .p-xs-9 {
    padding: 72px;
  }
  .pt-xs-9 {
    padding-top: 72px;
  }
  .pr-xs-9 {
    padding-right: 72px;
  }
  .pb-xs-9 {
    padding-bottom: 72px;
  }
  .pl-xs-9 {
    padding-left: 72px;
  }
  .m-xs-10 {
    margin: 80px;
  }
  .mt-xs-10 {
    margin-top: 80px;
  }
  .mr-xs-10 {
    margin-right: 80px;
  }
  .mb-xs-10 {
    margin-bottom: 80px;
  }
  .ml-xs-10 {
    margin-left: 80px;
  }
  .p-xs-10 {
    padding: 80px;
  }
  .pt-xs-10 {
    padding-top: 80px;
  }
  .pr-xs-10 {
    padding-right: 80px;
  }
  .pb-xs-10 {
    padding-bottom: 80px;
  }
  .pl-xs-10 {
    padding-left: 80px;
  }
  .m-xs-11 {
    margin: 88px;
  }
  .mt-xs-11 {
    margin-top: 88px;
  }
  .mr-xs-11 {
    margin-right: 88px;
  }
  .mb-xs-11 {
    margin-bottom: 88px;
  }
  .ml-xs-11 {
    margin-left: 88px;
  }
  .p-xs-11 {
    padding: 88px;
  }
  .pt-xs-11 {
    padding-top: 88px;
  }
  .pr-xs-11 {
    padding-right: 88px;
  }
  .pb-xs-11 {
    padding-bottom: 88px;
  }
  .pl-xs-11 {
    padding-left: 88px;
  }
  .m-xs-12 {
    margin: 96px;
  }
  .mt-xs-12 {
    margin-top: 96px;
  }
  .mr-xs-12 {
    margin-right: 96px;
  }
  .mb-xs-12 {
    margin-bottom: 96px;
  }
  .ml-xs-12 {
    margin-left: 96px;
  }
  .p-xs-12 {
    padding: 96px;
  }
  .pt-xs-12 {
    padding-top: 96px;
  }
  .pr-xs-12 {
    padding-right: 96px;
  }
  .pb-xs-12 {
    padding-bottom: 96px;
  }
  .pl-xs-12 {
    padding-left: 96px;
  }
  .m-xs-13 {
    margin: 104px;
  }
  .mt-xs-13 {
    margin-top: 104px;
  }
  .mr-xs-13 {
    margin-right: 104px;
  }
  .mb-xs-13 {
    margin-bottom: 104px;
  }
  .ml-xs-13 {
    margin-left: 104px;
  }
  .p-xs-13 {
    padding: 104px;
  }
  .pt-xs-13 {
    padding-top: 104px;
  }
  .pr-xs-13 {
    padding-right: 104px;
  }
  .pb-xs-13 {
    padding-bottom: 104px;
  }
  .pl-xs-13 {
    padding-left: 104px;
  }
  .m-xs-14 {
    margin: 112px;
  }
  .mt-xs-14 {
    margin-top: 112px;
  }
  .mr-xs-14 {
    margin-right: 112px;
  }
  .mb-xs-14 {
    margin-bottom: 112px;
  }
  .ml-xs-14 {
    margin-left: 112px;
  }
  .p-xs-14 {
    padding: 112px;
  }
  .pt-xs-14 {
    padding-top: 112px;
  }
  .pr-xs-14 {
    padding-right: 112px;
  }
  .pb-xs-14 {
    padding-bottom: 112px;
  }
  .pl-xs-14 {
    padding-left: 112px;
  }
  .m-xs-15 {
    margin: 120px;
  }
  .mt-xs-15 {
    margin-top: 120px;
  }
  .mr-xs-15 {
    margin-right: 120px;
  }
  .mb-xs-15 {
    margin-bottom: 120px;
  }
  .ml-xs-15 {
    margin-left: 120px;
  }
  .p-xs-15 {
    padding: 120px;
  }
  .pt-xs-15 {
    padding-top: 120px;
  }
  .pr-xs-15 {
    padding-right: 120px;
  }
  .pb-xs-15 {
    padding-bottom: 120px;
  }
  .pl-xs-15 {
    padding-left: 120px;
  }
  .m-xs-16 {
    margin: 128px;
  }
  .mt-xs-16 {
    margin-top: 128px;
  }
  .mr-xs-16 {
    margin-right: 128px;
  }
  .mb-xs-16 {
    margin-bottom: 128px;
  }
  .ml-xs-16 {
    margin-left: 128px;
  }
  .p-xs-16 {
    padding: 128px;
  }
  .pt-xs-16 {
    padding-top: 128px;
  }
  .pr-xs-16 {
    padding-right: 128px;
  }
  .pb-xs-16 {
    padding-bottom: 128px;
  }
  .pl-xs-16 {
    padding-left: 128px;
  }
  .m-xs-17 {
    margin: 136px;
  }
  .mt-xs-17 {
    margin-top: 136px;
  }
  .mr-xs-17 {
    margin-right: 136px;
  }
  .mb-xs-17 {
    margin-bottom: 136px;
  }
  .ml-xs-17 {
    margin-left: 136px;
  }
  .p-xs-17 {
    padding: 136px;
  }
  .pt-xs-17 {
    padding-top: 136px;
  }
  .pr-xs-17 {
    padding-right: 136px;
  }
  .pb-xs-17 {
    padding-bottom: 136px;
  }
  .pl-xs-17 {
    padding-left: 136px;
  }
  .m-xs-18 {
    margin: 144px;
  }
  .mt-xs-18 {
    margin-top: 144px;
  }
  .mr-xs-18 {
    margin-right: 144px;
  }
  .mb-xs-18 {
    margin-bottom: 144px;
  }
  .ml-xs-18 {
    margin-left: 144px;
  }
  .p-xs-18 {
    padding: 144px;
  }
  .pt-xs-18 {
    padding-top: 144px;
  }
  .pr-xs-18 {
    padding-right: 144px;
  }
  .pb-xs-18 {
    padding-bottom: 144px;
  }
  .pl-xs-18 {
    padding-left: 144px;
  }
  .m-xs-19 {
    margin: 152px;
  }
  .mt-xs-19 {
    margin-top: 152px;
  }
  .mr-xs-19 {
    margin-right: 152px;
  }
  .mb-xs-19 {
    margin-bottom: 152px;
  }
  .ml-xs-19 {
    margin-left: 152px;
  }
  .p-xs-19 {
    padding: 152px;
  }
  .pt-xs-19 {
    padding-top: 152px;
  }
  .pr-xs-19 {
    padding-right: 152px;
  }
  .pb-xs-19 {
    padding-bottom: 152px;
  }
  .pl-xs-19 {
    padding-left: 152px;
  }
  .m-xs-20 {
    margin: 160px;
  }
  .mt-xs-20 {
    margin-top: 160px;
  }
  .mr-xs-20 {
    margin-right: 160px;
  }
  .mb-xs-20 {
    margin-bottom: 160px;
  }
  .ml-xs-20 {
    margin-left: 160px;
  }
  .p-xs-20 {
    padding: 160px;
  }
  .pt-xs-20 {
    padding-top: 160px;
  }
  .pr-xs-20 {
    padding-right: 160px;
  }
  .pb-xs-20 {
    padding-bottom: 160px;
  }
  .pl-xs-20 {
    padding-left: 160px;
  }
}
@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0;
  }
  .mt-sm-0 {
    margin-top: 0;
  }
  .mr-sm-0 {
    margin-right: 0;
  }
  .mb-sm-0 {
    margin-bottom: 0;
  }
  .ml-sm-0 {
    margin-left: 0;
  }
  .p-sm-0 {
    padding: 0;
  }
  .pt-sm-0 {
    padding-top: 0;
  }
  .pr-sm-0 {
    padding-right: 0;
  }
  .pb-sm-0 {
    padding-bottom: 0;
  }
  .pl-sm-0 {
    padding-left: 0;
  }
  .m-sm-1 {
    margin: 8px;
  }
  .mt-sm-1 {
    margin-top: 8px;
  }
  .mr-sm-1 {
    margin-right: 8px;
  }
  .mb-sm-1 {
    margin-bottom: 8px;
  }
  .ml-sm-1 {
    margin-left: 8px;
  }
  .p-sm-1 {
    padding: 8px;
  }
  .pt-sm-1 {
    padding-top: 8px;
  }
  .pr-sm-1 {
    padding-right: 8px;
  }
  .pb-sm-1 {
    padding-bottom: 8px;
  }
  .pl-sm-1 {
    padding-left: 8px;
  }
  .m-sm-2 {
    margin: 16px;
  }
  .mt-sm-2 {
    margin-top: 16px;
  }
  .mr-sm-2 {
    margin-right: 16px;
  }
  .mb-sm-2 {
    margin-bottom: 16px;
  }
  .ml-sm-2 {
    margin-left: 16px;
  }
  .p-sm-2 {
    padding: 16px;
  }
  .pt-sm-2 {
    padding-top: 16px;
  }
  .pr-sm-2 {
    padding-right: 16px;
  }
  .pb-sm-2 {
    padding-bottom: 16px;
  }
  .pl-sm-2 {
    padding-left: 16px;
  }
  .m-sm-3 {
    margin: 24px;
  }
  .mt-sm-3 {
    margin-top: 24px;
  }
  .mr-sm-3 {
    margin-right: 24px;
  }
  .mb-sm-3 {
    margin-bottom: 24px;
  }
  .ml-sm-3 {
    margin-left: 24px;
  }
  .p-sm-3 {
    padding: 24px;
  }
  .pt-sm-3 {
    padding-top: 24px;
  }
  .pr-sm-3 {
    padding-right: 24px;
  }
  .pb-sm-3 {
    padding-bottom: 24px;
  }
  .pl-sm-3 {
    padding-left: 24px;
  }
  .m-sm-4 {
    margin: 32px;
  }
  .mt-sm-4 {
    margin-top: 32px;
  }
  .mr-sm-4 {
    margin-right: 32px;
  }
  .mb-sm-4 {
    margin-bottom: 32px;
  }
  .ml-sm-4 {
    margin-left: 32px;
  }
  .p-sm-4 {
    padding: 32px;
  }
  .pt-sm-4 {
    padding-top: 32px;
  }
  .pr-sm-4 {
    padding-right: 32px;
  }
  .pb-sm-4 {
    padding-bottom: 32px;
  }
  .pl-sm-4 {
    padding-left: 32px;
  }
  .m-sm-5 {
    margin: 40px;
  }
  .mt-sm-5 {
    margin-top: 40px;
  }
  .mr-sm-5 {
    margin-right: 40px;
  }
  .mb-sm-5 {
    margin-bottom: 40px;
  }
  .ml-sm-5 {
    margin-left: 40px;
  }
  .p-sm-5 {
    padding: 40px;
  }
  .pt-sm-5 {
    padding-top: 40px;
  }
  .pr-sm-5 {
    padding-right: 40px;
  }
  .pb-sm-5 {
    padding-bottom: 40px;
  }
  .pl-sm-5 {
    padding-left: 40px;
  }
  .m-sm-6 {
    margin: 48px;
  }
  .mt-sm-6 {
    margin-top: 48px;
  }
  .mr-sm-6 {
    margin-right: 48px;
  }
  .mb-sm-6 {
    margin-bottom: 48px;
  }
  .ml-sm-6 {
    margin-left: 48px;
  }
  .p-sm-6 {
    padding: 48px;
  }
  .pt-sm-6 {
    padding-top: 48px;
  }
  .pr-sm-6 {
    padding-right: 48px;
  }
  .pb-sm-6 {
    padding-bottom: 48px;
  }
  .pl-sm-6 {
    padding-left: 48px;
  }
  .m-sm-7 {
    margin: 56px;
  }
  .mt-sm-7 {
    margin-top: 56px;
  }
  .mr-sm-7 {
    margin-right: 56px;
  }
  .mb-sm-7 {
    margin-bottom: 56px;
  }
  .ml-sm-7 {
    margin-left: 56px;
  }
  .p-sm-7 {
    padding: 56px;
  }
  .pt-sm-7 {
    padding-top: 56px;
  }
  .pr-sm-7 {
    padding-right: 56px;
  }
  .pb-sm-7 {
    padding-bottom: 56px;
  }
  .pl-sm-7 {
    padding-left: 56px;
  }
  .m-sm-8 {
    margin: 64px;
  }
  .mt-sm-8 {
    margin-top: 64px;
  }
  .mr-sm-8 {
    margin-right: 64px;
  }
  .mb-sm-8 {
    margin-bottom: 64px;
  }
  .ml-sm-8 {
    margin-left: 64px;
  }
  .p-sm-8 {
    padding: 64px;
  }
  .pt-sm-8 {
    padding-top: 64px;
  }
  .pr-sm-8 {
    padding-right: 64px;
  }
  .pb-sm-8 {
    padding-bottom: 64px;
  }
  .pl-sm-8 {
    padding-left: 64px;
  }
  .m-sm-9 {
    margin: 72px;
  }
  .mt-sm-9 {
    margin-top: 72px;
  }
  .mr-sm-9 {
    margin-right: 72px;
  }
  .mb-sm-9 {
    margin-bottom: 72px;
  }
  .ml-sm-9 {
    margin-left: 72px;
  }
  .p-sm-9 {
    padding: 72px;
  }
  .pt-sm-9 {
    padding-top: 72px;
  }
  .pr-sm-9 {
    padding-right: 72px;
  }
  .pb-sm-9 {
    padding-bottom: 72px;
  }
  .pl-sm-9 {
    padding-left: 72px;
  }
  .m-sm-10 {
    margin: 80px;
  }
  .mt-sm-10 {
    margin-top: 80px;
  }
  .mr-sm-10 {
    margin-right: 80px;
  }
  .mb-sm-10 {
    margin-bottom: 80px;
  }
  .ml-sm-10 {
    margin-left: 80px;
  }
  .p-sm-10 {
    padding: 80px;
  }
  .pt-sm-10 {
    padding-top: 80px;
  }
  .pr-sm-10 {
    padding-right: 80px;
  }
  .pb-sm-10 {
    padding-bottom: 80px;
  }
  .pl-sm-10 {
    padding-left: 80px;
  }
  .m-sm-11 {
    margin: 88px;
  }
  .mt-sm-11 {
    margin-top: 88px;
  }
  .mr-sm-11 {
    margin-right: 88px;
  }
  .mb-sm-11 {
    margin-bottom: 88px;
  }
  .ml-sm-11 {
    margin-left: 88px;
  }
  .p-sm-11 {
    padding: 88px;
  }
  .pt-sm-11 {
    padding-top: 88px;
  }
  .pr-sm-11 {
    padding-right: 88px;
  }
  .pb-sm-11 {
    padding-bottom: 88px;
  }
  .pl-sm-11 {
    padding-left: 88px;
  }
  .m-sm-12 {
    margin: 96px;
  }
  .mt-sm-12 {
    margin-top: 96px;
  }
  .mr-sm-12 {
    margin-right: 96px;
  }
  .mb-sm-12 {
    margin-bottom: 96px;
  }
  .ml-sm-12 {
    margin-left: 96px;
  }
  .p-sm-12 {
    padding: 96px;
  }
  .pt-sm-12 {
    padding-top: 96px;
  }
  .pr-sm-12 {
    padding-right: 96px;
  }
  .pb-sm-12 {
    padding-bottom: 96px;
  }
  .pl-sm-12 {
    padding-left: 96px;
  }
  .m-sm-13 {
    margin: 104px;
  }
  .mt-sm-13 {
    margin-top: 104px;
  }
  .mr-sm-13 {
    margin-right: 104px;
  }
  .mb-sm-13 {
    margin-bottom: 104px;
  }
  .ml-sm-13 {
    margin-left: 104px;
  }
  .p-sm-13 {
    padding: 104px;
  }
  .pt-sm-13 {
    padding-top: 104px;
  }
  .pr-sm-13 {
    padding-right: 104px;
  }
  .pb-sm-13 {
    padding-bottom: 104px;
  }
  .pl-sm-13 {
    padding-left: 104px;
  }
  .m-sm-14 {
    margin: 112px;
  }
  .mt-sm-14 {
    margin-top: 112px;
  }
  .mr-sm-14 {
    margin-right: 112px;
  }
  .mb-sm-14 {
    margin-bottom: 112px;
  }
  .ml-sm-14 {
    margin-left: 112px;
  }
  .p-sm-14 {
    padding: 112px;
  }
  .pt-sm-14 {
    padding-top: 112px;
  }
  .pr-sm-14 {
    padding-right: 112px;
  }
  .pb-sm-14 {
    padding-bottom: 112px;
  }
  .pl-sm-14 {
    padding-left: 112px;
  }
  .m-sm-15 {
    margin: 120px;
  }
  .mt-sm-15 {
    margin-top: 120px;
  }
  .mr-sm-15 {
    margin-right: 120px;
  }
  .mb-sm-15 {
    margin-bottom: 120px;
  }
  .ml-sm-15 {
    margin-left: 120px;
  }
  .p-sm-15 {
    padding: 120px;
  }
  .pt-sm-15 {
    padding-top: 120px;
  }
  .pr-sm-15 {
    padding-right: 120px;
  }
  .pb-sm-15 {
    padding-bottom: 120px;
  }
  .pl-sm-15 {
    padding-left: 120px;
  }
  .m-sm-16 {
    margin: 128px;
  }
  .mt-sm-16 {
    margin-top: 128px;
  }
  .mr-sm-16 {
    margin-right: 128px;
  }
  .mb-sm-16 {
    margin-bottom: 128px;
  }
  .ml-sm-16 {
    margin-left: 128px;
  }
  .p-sm-16 {
    padding: 128px;
  }
  .pt-sm-16 {
    padding-top: 128px;
  }
  .pr-sm-16 {
    padding-right: 128px;
  }
  .pb-sm-16 {
    padding-bottom: 128px;
  }
  .pl-sm-16 {
    padding-left: 128px;
  }
  .m-sm-17 {
    margin: 136px;
  }
  .mt-sm-17 {
    margin-top: 136px;
  }
  .mr-sm-17 {
    margin-right: 136px;
  }
  .mb-sm-17 {
    margin-bottom: 136px;
  }
  .ml-sm-17 {
    margin-left: 136px;
  }
  .p-sm-17 {
    padding: 136px;
  }
  .pt-sm-17 {
    padding-top: 136px;
  }
  .pr-sm-17 {
    padding-right: 136px;
  }
  .pb-sm-17 {
    padding-bottom: 136px;
  }
  .pl-sm-17 {
    padding-left: 136px;
  }
  .m-sm-18 {
    margin: 144px;
  }
  .mt-sm-18 {
    margin-top: 144px;
  }
  .mr-sm-18 {
    margin-right: 144px;
  }
  .mb-sm-18 {
    margin-bottom: 144px;
  }
  .ml-sm-18 {
    margin-left: 144px;
  }
  .p-sm-18 {
    padding: 144px;
  }
  .pt-sm-18 {
    padding-top: 144px;
  }
  .pr-sm-18 {
    padding-right: 144px;
  }
  .pb-sm-18 {
    padding-bottom: 144px;
  }
  .pl-sm-18 {
    padding-left: 144px;
  }
  .m-sm-19 {
    margin: 152px;
  }
  .mt-sm-19 {
    margin-top: 152px;
  }
  .mr-sm-19 {
    margin-right: 152px;
  }
  .mb-sm-19 {
    margin-bottom: 152px;
  }
  .ml-sm-19 {
    margin-left: 152px;
  }
  .p-sm-19 {
    padding: 152px;
  }
  .pt-sm-19 {
    padding-top: 152px;
  }
  .pr-sm-19 {
    padding-right: 152px;
  }
  .pb-sm-19 {
    padding-bottom: 152px;
  }
  .pl-sm-19 {
    padding-left: 152px;
  }
  .m-sm-20 {
    margin: 160px;
  }
  .mt-sm-20 {
    margin-top: 160px;
  }
  .mr-sm-20 {
    margin-right: 160px;
  }
  .mb-sm-20 {
    margin-bottom: 160px;
  }
  .ml-sm-20 {
    margin-left: 160px;
  }
  .p-sm-20 {
    padding: 160px;
  }
  .pt-sm-20 {
    padding-top: 160px;
  }
  .pr-sm-20 {
    padding-right: 160px;
  }
  .pb-sm-20 {
    padding-bottom: 160px;
  }
  .pl-sm-20 {
    padding-left: 160px;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0;
  }
  .mt-md-0 {
    margin-top: 0;
  }
  .mr-md-0 {
    margin-right: 0;
  }
  .mb-md-0 {
    margin-bottom: 0;
  }
  .ml-md-0 {
    margin-left: 0;
  }
  .p-md-0 {
    padding: 0;
  }
  .pt-md-0 {
    padding-top: 0;
  }
  .pr-md-0 {
    padding-right: 0;
  }
  .pb-md-0 {
    padding-bottom: 0;
  }
  .pl-md-0 {
    padding-left: 0;
  }
  .m-md-1 {
    margin: 8px;
  }
  .mt-md-1 {
    margin-top: 8px;
  }
  .mr-md-1 {
    margin-right: 8px;
  }
  .mb-md-1 {
    margin-bottom: 8px;
  }
  .ml-md-1 {
    margin-left: 8px;
  }
  .p-md-1 {
    padding: 8px;
  }
  .pt-md-1 {
    padding-top: 8px;
  }
  .pr-md-1 {
    padding-right: 8px;
  }
  .pb-md-1 {
    padding-bottom: 8px;
  }
  .pl-md-1 {
    padding-left: 8px;
  }
  .m-md-2 {
    margin: 16px;
  }
  .mt-md-2 {
    margin-top: 16px;
  }
  .mr-md-2 {
    margin-right: 16px;
  }
  .mb-md-2 {
    margin-bottom: 16px;
  }
  .ml-md-2 {
    margin-left: 16px;
  }
  .p-md-2 {
    padding: 16px;
  }
  .pt-md-2 {
    padding-top: 16px;
  }
  .pr-md-2 {
    padding-right: 16px;
  }
  .pb-md-2 {
    padding-bottom: 16px;
  }
  .pl-md-2 {
    padding-left: 16px;
  }
  .m-md-3 {
    margin: 24px;
  }
  .mt-md-3 {
    margin-top: 24px;
  }
  .mr-md-3 {
    margin-right: 24px;
  }
  .mb-md-3 {
    margin-bottom: 24px;
  }
  .ml-md-3 {
    margin-left: 24px;
  }
  .p-md-3 {
    padding: 24px;
  }
  .pt-md-3 {
    padding-top: 24px;
  }
  .pr-md-3 {
    padding-right: 24px;
  }
  .pb-md-3 {
    padding-bottom: 24px;
  }
  .pl-md-3 {
    padding-left: 24px;
  }
  .m-md-4 {
    margin: 32px;
  }
  .mt-md-4 {
    margin-top: 32px;
  }
  .mr-md-4 {
    margin-right: 32px;
  }
  .mb-md-4 {
    margin-bottom: 32px;
  }
  .ml-md-4 {
    margin-left: 32px;
  }
  .p-md-4 {
    padding: 32px;
  }
  .pt-md-4 {
    padding-top: 32px;
  }
  .pr-md-4 {
    padding-right: 32px;
  }
  .pb-md-4 {
    padding-bottom: 32px;
  }
  .pl-md-4 {
    padding-left: 32px;
  }
  .m-md-5 {
    margin: 40px;
  }
  .mt-md-5 {
    margin-top: 40px;
  }
  .mr-md-5 {
    margin-right: 40px;
  }
  .mb-md-5 {
    margin-bottom: 40px;
  }
  .ml-md-5 {
    margin-left: 40px;
  }
  .p-md-5 {
    padding: 40px;
  }
  .pt-md-5 {
    padding-top: 40px;
  }
  .pr-md-5 {
    padding-right: 40px;
  }
  .pb-md-5 {
    padding-bottom: 40px;
  }
  .pl-md-5 {
    padding-left: 40px;
  }
  .m-md-6 {
    margin: 48px;
  }
  .mt-md-6 {
    margin-top: 48px;
  }
  .mr-md-6 {
    margin-right: 48px;
  }
  .mb-md-6 {
    margin-bottom: 48px;
  }
  .ml-md-6 {
    margin-left: 48px;
  }
  .p-md-6 {
    padding: 48px;
  }
  .pt-md-6 {
    padding-top: 48px;
  }
  .pr-md-6 {
    padding-right: 48px;
  }
  .pb-md-6 {
    padding-bottom: 48px;
  }
  .pl-md-6 {
    padding-left: 48px;
  }
  .m-md-7 {
    margin: 56px;
  }
  .mt-md-7 {
    margin-top: 56px;
  }
  .mr-md-7 {
    margin-right: 56px;
  }
  .mb-md-7 {
    margin-bottom: 56px;
  }
  .ml-md-7 {
    margin-left: 56px;
  }
  .p-md-7 {
    padding: 56px;
  }
  .pt-md-7 {
    padding-top: 56px;
  }
  .pr-md-7 {
    padding-right: 56px;
  }
  .pb-md-7 {
    padding-bottom: 56px;
  }
  .pl-md-7 {
    padding-left: 56px;
  }
  .m-md-8 {
    margin: 64px;
  }
  .mt-md-8 {
    margin-top: 64px;
  }
  .mr-md-8 {
    margin-right: 64px;
  }
  .mb-md-8 {
    margin-bottom: 64px;
  }
  .ml-md-8 {
    margin-left: 64px;
  }
  .p-md-8 {
    padding: 64px;
  }
  .pt-md-8 {
    padding-top: 64px;
  }
  .pr-md-8 {
    padding-right: 64px;
  }
  .pb-md-8 {
    padding-bottom: 64px;
  }
  .pl-md-8 {
    padding-left: 64px;
  }
  .m-md-9 {
    margin: 72px;
  }
  .mt-md-9 {
    margin-top: 72px;
  }
  .mr-md-9 {
    margin-right: 72px;
  }
  .mb-md-9 {
    margin-bottom: 72px;
  }
  .ml-md-9 {
    margin-left: 72px;
  }
  .p-md-9 {
    padding: 72px;
  }
  .pt-md-9 {
    padding-top: 72px;
  }
  .pr-md-9 {
    padding-right: 72px;
  }
  .pb-md-9 {
    padding-bottom: 72px;
  }
  .pl-md-9 {
    padding-left: 72px;
  }
  .m-md-10 {
    margin: 80px;
  }
  .mt-md-10 {
    margin-top: 80px;
  }
  .mr-md-10 {
    margin-right: 80px;
  }
  .mb-md-10 {
    margin-bottom: 80px;
  }
  .ml-md-10 {
    margin-left: 80px;
  }
  .p-md-10 {
    padding: 80px;
  }
  .pt-md-10 {
    padding-top: 80px;
  }
  .pr-md-10 {
    padding-right: 80px;
  }
  .pb-md-10 {
    padding-bottom: 80px;
  }
  .pl-md-10 {
    padding-left: 80px;
  }
  .m-md-11 {
    margin: 88px;
  }
  .mt-md-11 {
    margin-top: 88px;
  }
  .mr-md-11 {
    margin-right: 88px;
  }
  .mb-md-11 {
    margin-bottom: 88px;
  }
  .ml-md-11 {
    margin-left: 88px;
  }
  .p-md-11 {
    padding: 88px;
  }
  .pt-md-11 {
    padding-top: 88px;
  }
  .pr-md-11 {
    padding-right: 88px;
  }
  .pb-md-11 {
    padding-bottom: 88px;
  }
  .pl-md-11 {
    padding-left: 88px;
  }
  .m-md-12 {
    margin: 96px;
  }
  .mt-md-12 {
    margin-top: 96px;
  }
  .mr-md-12 {
    margin-right: 96px;
  }
  .mb-md-12 {
    margin-bottom: 96px;
  }
  .ml-md-12 {
    margin-left: 96px;
  }
  .p-md-12 {
    padding: 96px;
  }
  .pt-md-12 {
    padding-top: 96px;
  }
  .pr-md-12 {
    padding-right: 96px;
  }
  .pb-md-12 {
    padding-bottom: 96px;
  }
  .pl-md-12 {
    padding-left: 96px;
  }
  .m-md-13 {
    margin: 104px;
  }
  .mt-md-13 {
    margin-top: 104px;
  }
  .mr-md-13 {
    margin-right: 104px;
  }
  .mb-md-13 {
    margin-bottom: 104px;
  }
  .ml-md-13 {
    margin-left: 104px;
  }
  .p-md-13 {
    padding: 104px;
  }
  .pt-md-13 {
    padding-top: 104px;
  }
  .pr-md-13 {
    padding-right: 104px;
  }
  .pb-md-13 {
    padding-bottom: 104px;
  }
  .pl-md-13 {
    padding-left: 104px;
  }
  .m-md-14 {
    margin: 112px;
  }
  .mt-md-14 {
    margin-top: 112px;
  }
  .mr-md-14 {
    margin-right: 112px;
  }
  .mb-md-14 {
    margin-bottom: 112px;
  }
  .ml-md-14 {
    margin-left: 112px;
  }
  .p-md-14 {
    padding: 112px;
  }
  .pt-md-14 {
    padding-top: 112px;
  }
  .pr-md-14 {
    padding-right: 112px;
  }
  .pb-md-14 {
    padding-bottom: 112px;
  }
  .pl-md-14 {
    padding-left: 112px;
  }
  .m-md-15 {
    margin: 120px;
  }
  .mt-md-15 {
    margin-top: 120px;
  }
  .mr-md-15 {
    margin-right: 120px;
  }
  .mb-md-15 {
    margin-bottom: 120px;
  }
  .ml-md-15 {
    margin-left: 120px;
  }
  .p-md-15 {
    padding: 120px;
  }
  .pt-md-15 {
    padding-top: 120px;
  }
  .pr-md-15 {
    padding-right: 120px;
  }
  .pb-md-15 {
    padding-bottom: 120px;
  }
  .pl-md-15 {
    padding-left: 120px;
  }
  .m-md-16 {
    margin: 128px;
  }
  .mt-md-16 {
    margin-top: 128px;
  }
  .mr-md-16 {
    margin-right: 128px;
  }
  .mb-md-16 {
    margin-bottom: 128px;
  }
  .ml-md-16 {
    margin-left: 128px;
  }
  .p-md-16 {
    padding: 128px;
  }
  .pt-md-16 {
    padding-top: 128px;
  }
  .pr-md-16 {
    padding-right: 128px;
  }
  .pb-md-16 {
    padding-bottom: 128px;
  }
  .pl-md-16 {
    padding-left: 128px;
  }
  .m-md-17 {
    margin: 136px;
  }
  .mt-md-17 {
    margin-top: 136px;
  }
  .mr-md-17 {
    margin-right: 136px;
  }
  .mb-md-17 {
    margin-bottom: 136px;
  }
  .ml-md-17 {
    margin-left: 136px;
  }
  .p-md-17 {
    padding: 136px;
  }
  .pt-md-17 {
    padding-top: 136px;
  }
  .pr-md-17 {
    padding-right: 136px;
  }
  .pb-md-17 {
    padding-bottom: 136px;
  }
  .pl-md-17 {
    padding-left: 136px;
  }
  .m-md-18 {
    margin: 144px;
  }
  .mt-md-18 {
    margin-top: 144px;
  }
  .mr-md-18 {
    margin-right: 144px;
  }
  .mb-md-18 {
    margin-bottom: 144px;
  }
  .ml-md-18 {
    margin-left: 144px;
  }
  .p-md-18 {
    padding: 144px;
  }
  .pt-md-18 {
    padding-top: 144px;
  }
  .pr-md-18 {
    padding-right: 144px;
  }
  .pb-md-18 {
    padding-bottom: 144px;
  }
  .pl-md-18 {
    padding-left: 144px;
  }
  .m-md-19 {
    margin: 152px;
  }
  .mt-md-19 {
    margin-top: 152px;
  }
  .mr-md-19 {
    margin-right: 152px;
  }
  .mb-md-19 {
    margin-bottom: 152px;
  }
  .ml-md-19 {
    margin-left: 152px;
  }
  .p-md-19 {
    padding: 152px;
  }
  .pt-md-19 {
    padding-top: 152px;
  }
  .pr-md-19 {
    padding-right: 152px;
  }
  .pb-md-19 {
    padding-bottom: 152px;
  }
  .pl-md-19 {
    padding-left: 152px;
  }
  .m-md-20 {
    margin: 160px;
  }
  .mt-md-20 {
    margin-top: 160px;
  }
  .mr-md-20 {
    margin-right: 160px;
  }
  .mb-md-20 {
    margin-bottom: 160px;
  }
  .ml-md-20 {
    margin-left: 160px;
  }
  .p-md-20 {
    padding: 160px;
  }
  .pt-md-20 {
    padding-top: 160px;
  }
  .pr-md-20 {
    padding-right: 160px;
  }
  .pb-md-20 {
    padding-bottom: 160px;
  }
  .pl-md-20 {
    padding-left: 160px;
  }
}
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0;
  }
  .mt-lg-0 {
    margin-top: 0;
  }
  .mr-lg-0 {
    margin-right: 0;
  }
  .mb-lg-0 {
    margin-bottom: 0;
  }
  .ml-lg-0 {
    margin-left: 0;
  }
  .p-lg-0 {
    padding: 0;
  }
  .pt-lg-0 {
    padding-top: 0;
  }
  .pr-lg-0 {
    padding-right: 0;
  }
  .pb-lg-0 {
    padding-bottom: 0;
  }
  .pl-lg-0 {
    padding-left: 0;
  }
  .m-lg-1 {
    margin: 8px;
  }
  .mt-lg-1 {
    margin-top: 8px;
  }
  .mr-lg-1 {
    margin-right: 8px;
  }
  .mb-lg-1 {
    margin-bottom: 8px;
  }
  .ml-lg-1 {
    margin-left: 8px;
  }
  .p-lg-1 {
    padding: 8px;
  }
  .pt-lg-1 {
    padding-top: 8px;
  }
  .pr-lg-1 {
    padding-right: 8px;
  }
  .pb-lg-1 {
    padding-bottom: 8px;
  }
  .pl-lg-1 {
    padding-left: 8px;
  }
  .m-lg-2 {
    margin: 16px;
  }
  .mt-lg-2 {
    margin-top: 16px;
  }
  .mr-lg-2 {
    margin-right: 16px;
  }
  .mb-lg-2 {
    margin-bottom: 16px;
  }
  .ml-lg-2 {
    margin-left: 16px;
  }
  .p-lg-2 {
    padding: 16px;
  }
  .pt-lg-2 {
    padding-top: 16px;
  }
  .pr-lg-2 {
    padding-right: 16px;
  }
  .pb-lg-2 {
    padding-bottom: 16px;
  }
  .pl-lg-2 {
    padding-left: 16px;
  }
  .m-lg-3 {
    margin: 24px;
  }
  .mt-lg-3 {
    margin-top: 24px;
  }
  .mr-lg-3 {
    margin-right: 24px;
  }
  .mb-lg-3 {
    margin-bottom: 24px;
  }
  .ml-lg-3 {
    margin-left: 24px;
  }
  .p-lg-3 {
    padding: 24px;
  }
  .pt-lg-3 {
    padding-top: 24px;
  }
  .pr-lg-3 {
    padding-right: 24px;
  }
  .pb-lg-3 {
    padding-bottom: 24px;
  }
  .pl-lg-3 {
    padding-left: 24px;
  }
  .m-lg-4 {
    margin: 32px;
  }
  .mt-lg-4 {
    margin-top: 32px;
  }
  .mr-lg-4 {
    margin-right: 32px;
  }
  .mb-lg-4 {
    margin-bottom: 32px;
  }
  .ml-lg-4 {
    margin-left: 32px;
  }
  .p-lg-4 {
    padding: 32px;
  }
  .pt-lg-4 {
    padding-top: 32px;
  }
  .pr-lg-4 {
    padding-right: 32px;
  }
  .pb-lg-4 {
    padding-bottom: 32px;
  }
  .pl-lg-4 {
    padding-left: 32px;
  }
  .m-lg-5 {
    margin: 40px;
  }
  .mt-lg-5 {
    margin-top: 40px;
  }
  .mr-lg-5 {
    margin-right: 40px;
  }
  .mb-lg-5 {
    margin-bottom: 40px;
  }
  .ml-lg-5 {
    margin-left: 40px;
  }
  .p-lg-5 {
    padding: 40px;
  }
  .pt-lg-5 {
    padding-top: 40px;
  }
  .pr-lg-5 {
    padding-right: 40px;
  }
  .pb-lg-5 {
    padding-bottom: 40px;
  }
  .pl-lg-5 {
    padding-left: 40px;
  }
  .m-lg-6 {
    margin: 48px;
  }
  .mt-lg-6 {
    margin-top: 48px;
  }
  .mr-lg-6 {
    margin-right: 48px;
  }
  .mb-lg-6 {
    margin-bottom: 48px;
  }
  .ml-lg-6 {
    margin-left: 48px;
  }
  .p-lg-6 {
    padding: 48px;
  }
  .pt-lg-6 {
    padding-top: 48px;
  }
  .pr-lg-6 {
    padding-right: 48px;
  }
  .pb-lg-6 {
    padding-bottom: 48px;
  }
  .pl-lg-6 {
    padding-left: 48px;
  }
  .m-lg-7 {
    margin: 56px;
  }
  .mt-lg-7 {
    margin-top: 56px;
  }
  .mr-lg-7 {
    margin-right: 56px;
  }
  .mb-lg-7 {
    margin-bottom: 56px;
  }
  .ml-lg-7 {
    margin-left: 56px;
  }
  .p-lg-7 {
    padding: 56px;
  }
  .pt-lg-7 {
    padding-top: 56px;
  }
  .pr-lg-7 {
    padding-right: 56px;
  }
  .pb-lg-7 {
    padding-bottom: 56px;
  }
  .pl-lg-7 {
    padding-left: 56px;
  }
  .m-lg-8 {
    margin: 64px;
  }
  .mt-lg-8 {
    margin-top: 64px;
  }
  .mr-lg-8 {
    margin-right: 64px;
  }
  .mb-lg-8 {
    margin-bottom: 64px;
  }
  .ml-lg-8 {
    margin-left: 64px;
  }
  .p-lg-8 {
    padding: 64px;
  }
  .pt-lg-8 {
    padding-top: 64px;
  }
  .pr-lg-8 {
    padding-right: 64px;
  }
  .pb-lg-8 {
    padding-bottom: 64px;
  }
  .pl-lg-8 {
    padding-left: 64px;
  }
  .m-lg-9 {
    margin: 72px;
  }
  .mt-lg-9 {
    margin-top: 72px;
  }
  .mr-lg-9 {
    margin-right: 72px;
  }
  .mb-lg-9 {
    margin-bottom: 72px;
  }
  .ml-lg-9 {
    margin-left: 72px;
  }
  .p-lg-9 {
    padding: 72px;
  }
  .pt-lg-9 {
    padding-top: 72px;
  }
  .pr-lg-9 {
    padding-right: 72px;
  }
  .pb-lg-9 {
    padding-bottom: 72px;
  }
  .pl-lg-9 {
    padding-left: 72px;
  }
  .m-lg-10 {
    margin: 80px;
  }
  .mt-lg-10 {
    margin-top: 80px;
  }
  .mr-lg-10 {
    margin-right: 80px;
  }
  .mb-lg-10 {
    margin-bottom: 80px;
  }
  .ml-lg-10 {
    margin-left: 80px;
  }
  .p-lg-10 {
    padding: 80px;
  }
  .pt-lg-10 {
    padding-top: 80px;
  }
  .pr-lg-10 {
    padding-right: 80px;
  }
  .pb-lg-10 {
    padding-bottom: 80px;
  }
  .pl-lg-10 {
    padding-left: 80px;
  }
  .m-lg-11 {
    margin: 88px;
  }
  .mt-lg-11 {
    margin-top: 88px;
  }
  .mr-lg-11 {
    margin-right: 88px;
  }
  .mb-lg-11 {
    margin-bottom: 88px;
  }
  .ml-lg-11 {
    margin-left: 88px;
  }
  .p-lg-11 {
    padding: 88px;
  }
  .pt-lg-11 {
    padding-top: 88px;
  }
  .pr-lg-11 {
    padding-right: 88px;
  }
  .pb-lg-11 {
    padding-bottom: 88px;
  }
  .pl-lg-11 {
    padding-left: 88px;
  }
  .m-lg-12 {
    margin: 96px;
  }
  .mt-lg-12 {
    margin-top: 96px;
  }
  .mr-lg-12 {
    margin-right: 96px;
  }
  .mb-lg-12 {
    margin-bottom: 96px;
  }
  .ml-lg-12 {
    margin-left: 96px;
  }
  .p-lg-12 {
    padding: 96px;
  }
  .pt-lg-12 {
    padding-top: 96px;
  }
  .pr-lg-12 {
    padding-right: 96px;
  }
  .pb-lg-12 {
    padding-bottom: 96px;
  }
  .pl-lg-12 {
    padding-left: 96px;
  }
  .m-lg-13 {
    margin: 104px;
  }
  .mt-lg-13 {
    margin-top: 104px;
  }
  .mr-lg-13 {
    margin-right: 104px;
  }
  .mb-lg-13 {
    margin-bottom: 104px;
  }
  .ml-lg-13 {
    margin-left: 104px;
  }
  .p-lg-13 {
    padding: 104px;
  }
  .pt-lg-13 {
    padding-top: 104px;
  }
  .pr-lg-13 {
    padding-right: 104px;
  }
  .pb-lg-13 {
    padding-bottom: 104px;
  }
  .pl-lg-13 {
    padding-left: 104px;
  }
  .m-lg-14 {
    margin: 112px;
  }
  .mt-lg-14 {
    margin-top: 112px;
  }
  .mr-lg-14 {
    margin-right: 112px;
  }
  .mb-lg-14 {
    margin-bottom: 112px;
  }
  .ml-lg-14 {
    margin-left: 112px;
  }
  .p-lg-14 {
    padding: 112px;
  }
  .pt-lg-14 {
    padding-top: 112px;
  }
  .pr-lg-14 {
    padding-right: 112px;
  }
  .pb-lg-14 {
    padding-bottom: 112px;
  }
  .pl-lg-14 {
    padding-left: 112px;
  }
  .m-lg-15 {
    margin: 120px;
  }
  .mt-lg-15 {
    margin-top: 120px;
  }
  .mr-lg-15 {
    margin-right: 120px;
  }
  .mb-lg-15 {
    margin-bottom: 120px;
  }
  .ml-lg-15 {
    margin-left: 120px;
  }
  .p-lg-15 {
    padding: 120px;
  }
  .pt-lg-15 {
    padding-top: 120px;
  }
  .pr-lg-15 {
    padding-right: 120px;
  }
  .pb-lg-15 {
    padding-bottom: 120px;
  }
  .pl-lg-15 {
    padding-left: 120px;
  }
  .m-lg-16 {
    margin: 128px;
  }
  .mt-lg-16 {
    margin-top: 128px;
  }
  .mr-lg-16 {
    margin-right: 128px;
  }
  .mb-lg-16 {
    margin-bottom: 128px;
  }
  .ml-lg-16 {
    margin-left: 128px;
  }
  .p-lg-16 {
    padding: 128px;
  }
  .pt-lg-16 {
    padding-top: 128px;
  }
  .pr-lg-16 {
    padding-right: 128px;
  }
  .pb-lg-16 {
    padding-bottom: 128px;
  }
  .pl-lg-16 {
    padding-left: 128px;
  }
  .m-lg-17 {
    margin: 136px;
  }
  .mt-lg-17 {
    margin-top: 136px;
  }
  .mr-lg-17 {
    margin-right: 136px;
  }
  .mb-lg-17 {
    margin-bottom: 136px;
  }
  .ml-lg-17 {
    margin-left: 136px;
  }
  .p-lg-17 {
    padding: 136px;
  }
  .pt-lg-17 {
    padding-top: 136px;
  }
  .pr-lg-17 {
    padding-right: 136px;
  }
  .pb-lg-17 {
    padding-bottom: 136px;
  }
  .pl-lg-17 {
    padding-left: 136px;
  }
  .m-lg-18 {
    margin: 144px;
  }
  .mt-lg-18 {
    margin-top: 144px;
  }
  .mr-lg-18 {
    margin-right: 144px;
  }
  .mb-lg-18 {
    margin-bottom: 144px;
  }
  .ml-lg-18 {
    margin-left: 144px;
  }
  .p-lg-18 {
    padding: 144px;
  }
  .pt-lg-18 {
    padding-top: 144px;
  }
  .pr-lg-18 {
    padding-right: 144px;
  }
  .pb-lg-18 {
    padding-bottom: 144px;
  }
  .pl-lg-18 {
    padding-left: 144px;
  }
  .m-lg-19 {
    margin: 152px;
  }
  .mt-lg-19 {
    margin-top: 152px;
  }
  .mr-lg-19 {
    margin-right: 152px;
  }
  .mb-lg-19 {
    margin-bottom: 152px;
  }
  .ml-lg-19 {
    margin-left: 152px;
  }
  .p-lg-19 {
    padding: 152px;
  }
  .pt-lg-19 {
    padding-top: 152px;
  }
  .pr-lg-19 {
    padding-right: 152px;
  }
  .pb-lg-19 {
    padding-bottom: 152px;
  }
  .pl-lg-19 {
    padding-left: 152px;
  }
  .m-lg-20 {
    margin: 160px;
  }
  .mt-lg-20 {
    margin-top: 160px;
  }
  .mr-lg-20 {
    margin-right: 160px;
  }
  .mb-lg-20 {
    margin-bottom: 160px;
  }
  .ml-lg-20 {
    margin-left: 160px;
  }
  .p-lg-20 {
    padding: 160px;
  }
  .pt-lg-20 {
    padding-top: 160px;
  }
  .pr-lg-20 {
    padding-right: 160px;
  }
  .pb-lg-20 {
    padding-bottom: 160px;
  }
  .pl-lg-20 {
    padding-left: 160px;
  }
}
@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0;
  }
  .mt-xl-0 {
    margin-top: 0;
  }
  .mr-xl-0 {
    margin-right: 0;
  }
  .mb-xl-0 {
    margin-bottom: 0;
  }
  .ml-xl-0 {
    margin-left: 0;
  }
  .p-xl-0 {
    padding: 0;
  }
  .pt-xl-0 {
    padding-top: 0;
  }
  .pr-xl-0 {
    padding-right: 0;
  }
  .pb-xl-0 {
    padding-bottom: 0;
  }
  .pl-xl-0 {
    padding-left: 0;
  }
  .m-xl-1 {
    margin: 8px;
  }
  .mt-xl-1 {
    margin-top: 8px;
  }
  .mr-xl-1 {
    margin-right: 8px;
  }
  .mb-xl-1 {
    margin-bottom: 8px;
  }
  .ml-xl-1 {
    margin-left: 8px;
  }
  .p-xl-1 {
    padding: 8px;
  }
  .pt-xl-1 {
    padding-top: 8px;
  }
  .pr-xl-1 {
    padding-right: 8px;
  }
  .pb-xl-1 {
    padding-bottom: 8px;
  }
  .pl-xl-1 {
    padding-left: 8px;
  }
  .m-xl-2 {
    margin: 16px;
  }
  .mt-xl-2 {
    margin-top: 16px;
  }
  .mr-xl-2 {
    margin-right: 16px;
  }
  .mb-xl-2 {
    margin-bottom: 16px;
  }
  .ml-xl-2 {
    margin-left: 16px;
  }
  .p-xl-2 {
    padding: 16px;
  }
  .pt-xl-2 {
    padding-top: 16px;
  }
  .pr-xl-2 {
    padding-right: 16px;
  }
  .pb-xl-2 {
    padding-bottom: 16px;
  }
  .pl-xl-2 {
    padding-left: 16px;
  }
  .m-xl-3 {
    margin: 24px;
  }
  .mt-xl-3 {
    margin-top: 24px;
  }
  .mr-xl-3 {
    margin-right: 24px;
  }
  .mb-xl-3 {
    margin-bottom: 24px;
  }
  .ml-xl-3 {
    margin-left: 24px;
  }
  .p-xl-3 {
    padding: 24px;
  }
  .pt-xl-3 {
    padding-top: 24px;
  }
  .pr-xl-3 {
    padding-right: 24px;
  }
  .pb-xl-3 {
    padding-bottom: 24px;
  }
  .pl-xl-3 {
    padding-left: 24px;
  }
  .m-xl-4 {
    margin: 32px;
  }
  .mt-xl-4 {
    margin-top: 32px;
  }
  .mr-xl-4 {
    margin-right: 32px;
  }
  .mb-xl-4 {
    margin-bottom: 32px;
  }
  .ml-xl-4 {
    margin-left: 32px;
  }
  .p-xl-4 {
    padding: 32px;
  }
  .pt-xl-4 {
    padding-top: 32px;
  }
  .pr-xl-4 {
    padding-right: 32px;
  }
  .pb-xl-4 {
    padding-bottom: 32px;
  }
  .pl-xl-4 {
    padding-left: 32px;
  }
  .m-xl-5 {
    margin: 40px;
  }
  .mt-xl-5 {
    margin-top: 40px;
  }
  .mr-xl-5 {
    margin-right: 40px;
  }
  .mb-xl-5 {
    margin-bottom: 40px;
  }
  .ml-xl-5 {
    margin-left: 40px;
  }
  .p-xl-5 {
    padding: 40px;
  }
  .pt-xl-5 {
    padding-top: 40px;
  }
  .pr-xl-5 {
    padding-right: 40px;
  }
  .pb-xl-5 {
    padding-bottom: 40px;
  }
  .pl-xl-5 {
    padding-left: 40px;
  }
  .m-xl-6 {
    margin: 48px;
  }
  .mt-xl-6 {
    margin-top: 48px;
  }
  .mr-xl-6 {
    margin-right: 48px;
  }
  .mb-xl-6 {
    margin-bottom: 48px;
  }
  .ml-xl-6 {
    margin-left: 48px;
  }
  .p-xl-6 {
    padding: 48px;
  }
  .pt-xl-6 {
    padding-top: 48px;
  }
  .pr-xl-6 {
    padding-right: 48px;
  }
  .pb-xl-6 {
    padding-bottom: 48px;
  }
  .pl-xl-6 {
    padding-left: 48px;
  }
  .m-xl-7 {
    margin: 56px;
  }
  .mt-xl-7 {
    margin-top: 56px;
  }
  .mr-xl-7 {
    margin-right: 56px;
  }
  .mb-xl-7 {
    margin-bottom: 56px;
  }
  .ml-xl-7 {
    margin-left: 56px;
  }
  .p-xl-7 {
    padding: 56px;
  }
  .pt-xl-7 {
    padding-top: 56px;
  }
  .pr-xl-7 {
    padding-right: 56px;
  }
  .pb-xl-7 {
    padding-bottom: 56px;
  }
  .pl-xl-7 {
    padding-left: 56px;
  }
  .m-xl-8 {
    margin: 64px;
  }
  .mt-xl-8 {
    margin-top: 64px;
  }
  .mr-xl-8 {
    margin-right: 64px;
  }
  .mb-xl-8 {
    margin-bottom: 64px;
  }
  .ml-xl-8 {
    margin-left: 64px;
  }
  .p-xl-8 {
    padding: 64px;
  }
  .pt-xl-8 {
    padding-top: 64px;
  }
  .pr-xl-8 {
    padding-right: 64px;
  }
  .pb-xl-8 {
    padding-bottom: 64px;
  }
  .pl-xl-8 {
    padding-left: 64px;
  }
  .m-xl-9 {
    margin: 72px;
  }
  .mt-xl-9 {
    margin-top: 72px;
  }
  .mr-xl-9 {
    margin-right: 72px;
  }
  .mb-xl-9 {
    margin-bottom: 72px;
  }
  .ml-xl-9 {
    margin-left: 72px;
  }
  .p-xl-9 {
    padding: 72px;
  }
  .pt-xl-9 {
    padding-top: 72px;
  }
  .pr-xl-9 {
    padding-right: 72px;
  }
  .pb-xl-9 {
    padding-bottom: 72px;
  }
  .pl-xl-9 {
    padding-left: 72px;
  }
  .m-xl-10 {
    margin: 80px;
  }
  .mt-xl-10 {
    margin-top: 80px;
  }
  .mr-xl-10 {
    margin-right: 80px;
  }
  .mb-xl-10 {
    margin-bottom: 80px;
  }
  .ml-xl-10 {
    margin-left: 80px;
  }
  .p-xl-10 {
    padding: 80px;
  }
  .pt-xl-10 {
    padding-top: 80px;
  }
  .pr-xl-10 {
    padding-right: 80px;
  }
  .pb-xl-10 {
    padding-bottom: 80px;
  }
  .pl-xl-10 {
    padding-left: 80px;
  }
  .m-xl-11 {
    margin: 88px;
  }
  .mt-xl-11 {
    margin-top: 88px;
  }
  .mr-xl-11 {
    margin-right: 88px;
  }
  .mb-xl-11 {
    margin-bottom: 88px;
  }
  .ml-xl-11 {
    margin-left: 88px;
  }
  .p-xl-11 {
    padding: 88px;
  }
  .pt-xl-11 {
    padding-top: 88px;
  }
  .pr-xl-11 {
    padding-right: 88px;
  }
  .pb-xl-11 {
    padding-bottom: 88px;
  }
  .pl-xl-11 {
    padding-left: 88px;
  }
  .m-xl-12 {
    margin: 96px;
  }
  .mt-xl-12 {
    margin-top: 96px;
  }
  .mr-xl-12 {
    margin-right: 96px;
  }
  .mb-xl-12 {
    margin-bottom: 96px;
  }
  .ml-xl-12 {
    margin-left: 96px;
  }
  .p-xl-12 {
    padding: 96px;
  }
  .pt-xl-12 {
    padding-top: 96px;
  }
  .pr-xl-12 {
    padding-right: 96px;
  }
  .pb-xl-12 {
    padding-bottom: 96px;
  }
  .pl-xl-12 {
    padding-left: 96px;
  }
  .m-xl-13 {
    margin: 104px;
  }
  .mt-xl-13 {
    margin-top: 104px;
  }
  .mr-xl-13 {
    margin-right: 104px;
  }
  .mb-xl-13 {
    margin-bottom: 104px;
  }
  .ml-xl-13 {
    margin-left: 104px;
  }
  .p-xl-13 {
    padding: 104px;
  }
  .pt-xl-13 {
    padding-top: 104px;
  }
  .pr-xl-13 {
    padding-right: 104px;
  }
  .pb-xl-13 {
    padding-bottom: 104px;
  }
  .pl-xl-13 {
    padding-left: 104px;
  }
  .m-xl-14 {
    margin: 112px;
  }
  .mt-xl-14 {
    margin-top: 112px;
  }
  .mr-xl-14 {
    margin-right: 112px;
  }
  .mb-xl-14 {
    margin-bottom: 112px;
  }
  .ml-xl-14 {
    margin-left: 112px;
  }
  .p-xl-14 {
    padding: 112px;
  }
  .pt-xl-14 {
    padding-top: 112px;
  }
  .pr-xl-14 {
    padding-right: 112px;
  }
  .pb-xl-14 {
    padding-bottom: 112px;
  }
  .pl-xl-14 {
    padding-left: 112px;
  }
  .m-xl-15 {
    margin: 120px;
  }
  .mt-xl-15 {
    margin-top: 120px;
  }
  .mr-xl-15 {
    margin-right: 120px;
  }
  .mb-xl-15 {
    margin-bottom: 120px;
  }
  .ml-xl-15 {
    margin-left: 120px;
  }
  .p-xl-15 {
    padding: 120px;
  }
  .pt-xl-15 {
    padding-top: 120px;
  }
  .pr-xl-15 {
    padding-right: 120px;
  }
  .pb-xl-15 {
    padding-bottom: 120px;
  }
  .pl-xl-15 {
    padding-left: 120px;
  }
  .m-xl-16 {
    margin: 128px;
  }
  .mt-xl-16 {
    margin-top: 128px;
  }
  .mr-xl-16 {
    margin-right: 128px;
  }
  .mb-xl-16 {
    margin-bottom: 128px;
  }
  .ml-xl-16 {
    margin-left: 128px;
  }
  .p-xl-16 {
    padding: 128px;
  }
  .pt-xl-16 {
    padding-top: 128px;
  }
  .pr-xl-16 {
    padding-right: 128px;
  }
  .pb-xl-16 {
    padding-bottom: 128px;
  }
  .pl-xl-16 {
    padding-left: 128px;
  }
  .m-xl-17 {
    margin: 136px;
  }
  .mt-xl-17 {
    margin-top: 136px;
  }
  .mr-xl-17 {
    margin-right: 136px;
  }
  .mb-xl-17 {
    margin-bottom: 136px;
  }
  .ml-xl-17 {
    margin-left: 136px;
  }
  .p-xl-17 {
    padding: 136px;
  }
  .pt-xl-17 {
    padding-top: 136px;
  }
  .pr-xl-17 {
    padding-right: 136px;
  }
  .pb-xl-17 {
    padding-bottom: 136px;
  }
  .pl-xl-17 {
    padding-left: 136px;
  }
  .m-xl-18 {
    margin: 144px;
  }
  .mt-xl-18 {
    margin-top: 144px;
  }
  .mr-xl-18 {
    margin-right: 144px;
  }
  .mb-xl-18 {
    margin-bottom: 144px;
  }
  .ml-xl-18 {
    margin-left: 144px;
  }
  .p-xl-18 {
    padding: 144px;
  }
  .pt-xl-18 {
    padding-top: 144px;
  }
  .pr-xl-18 {
    padding-right: 144px;
  }
  .pb-xl-18 {
    padding-bottom: 144px;
  }
  .pl-xl-18 {
    padding-left: 144px;
  }
  .m-xl-19 {
    margin: 152px;
  }
  .mt-xl-19 {
    margin-top: 152px;
  }
  .mr-xl-19 {
    margin-right: 152px;
  }
  .mb-xl-19 {
    margin-bottom: 152px;
  }
  .ml-xl-19 {
    margin-left: 152px;
  }
  .p-xl-19 {
    padding: 152px;
  }
  .pt-xl-19 {
    padding-top: 152px;
  }
  .pr-xl-19 {
    padding-right: 152px;
  }
  .pb-xl-19 {
    padding-bottom: 152px;
  }
  .pl-xl-19 {
    padding-left: 152px;
  }
  .m-xl-20 {
    margin: 160px;
  }
  .mt-xl-20 {
    margin-top: 160px;
  }
  .mr-xl-20 {
    margin-right: 160px;
  }
  .mb-xl-20 {
    margin-bottom: 160px;
  }
  .ml-xl-20 {
    margin-left: 160px;
  }
  .p-xl-20 {
    padding: 160px;
  }
  .pt-xl-20 {
    padding-top: 160px;
  }
  .pr-xl-20 {
    padding-right: 160px;
  }
  .pb-xl-20 {
    padding-bottom: 160px;
  }
  .pl-xl-20 {
    padding-left: 160px;
  }
}
@media (min-width: 1400px) {
  .m-xxl-0 {
    margin: 0;
  }
  .mt-xxl-0 {
    margin-top: 0;
  }
  .mr-xxl-0 {
    margin-right: 0;
  }
  .mb-xxl-0 {
    margin-bottom: 0;
  }
  .ml-xxl-0 {
    margin-left: 0;
  }
  .p-xxl-0 {
    padding: 0;
  }
  .pt-xxl-0 {
    padding-top: 0;
  }
  .pr-xxl-0 {
    padding-right: 0;
  }
  .pb-xxl-0 {
    padding-bottom: 0;
  }
  .pl-xxl-0 {
    padding-left: 0;
  }
  .m-xxl-1 {
    margin: 8px;
  }
  .mt-xxl-1 {
    margin-top: 8px;
  }
  .mr-xxl-1 {
    margin-right: 8px;
  }
  .mb-xxl-1 {
    margin-bottom: 8px;
  }
  .ml-xxl-1 {
    margin-left: 8px;
  }
  .p-xxl-1 {
    padding: 8px;
  }
  .pt-xxl-1 {
    padding-top: 8px;
  }
  .pr-xxl-1 {
    padding-right: 8px;
  }
  .pb-xxl-1 {
    padding-bottom: 8px;
  }
  .pl-xxl-1 {
    padding-left: 8px;
  }
  .m-xxl-2 {
    margin: 16px;
  }
  .mt-xxl-2 {
    margin-top: 16px;
  }
  .mr-xxl-2 {
    margin-right: 16px;
  }
  .mb-xxl-2 {
    margin-bottom: 16px;
  }
  .ml-xxl-2 {
    margin-left: 16px;
  }
  .p-xxl-2 {
    padding: 16px;
  }
  .pt-xxl-2 {
    padding-top: 16px;
  }
  .pr-xxl-2 {
    padding-right: 16px;
  }
  .pb-xxl-2 {
    padding-bottom: 16px;
  }
  .pl-xxl-2 {
    padding-left: 16px;
  }
  .m-xxl-3 {
    margin: 24px;
  }
  .mt-xxl-3 {
    margin-top: 24px;
  }
  .mr-xxl-3 {
    margin-right: 24px;
  }
  .mb-xxl-3 {
    margin-bottom: 24px;
  }
  .ml-xxl-3 {
    margin-left: 24px;
  }
  .p-xxl-3 {
    padding: 24px;
  }
  .pt-xxl-3 {
    padding-top: 24px;
  }
  .pr-xxl-3 {
    padding-right: 24px;
  }
  .pb-xxl-3 {
    padding-bottom: 24px;
  }
  .pl-xxl-3 {
    padding-left: 24px;
  }
  .m-xxl-4 {
    margin: 32px;
  }
  .mt-xxl-4 {
    margin-top: 32px;
  }
  .mr-xxl-4 {
    margin-right: 32px;
  }
  .mb-xxl-4 {
    margin-bottom: 32px;
  }
  .ml-xxl-4 {
    margin-left: 32px;
  }
  .p-xxl-4 {
    padding: 32px;
  }
  .pt-xxl-4 {
    padding-top: 32px;
  }
  .pr-xxl-4 {
    padding-right: 32px;
  }
  .pb-xxl-4 {
    padding-bottom: 32px;
  }
  .pl-xxl-4 {
    padding-left: 32px;
  }
  .m-xxl-5 {
    margin: 40px;
  }
  .mt-xxl-5 {
    margin-top: 40px;
  }
  .mr-xxl-5 {
    margin-right: 40px;
  }
  .mb-xxl-5 {
    margin-bottom: 40px;
  }
  .ml-xxl-5 {
    margin-left: 40px;
  }
  .p-xxl-5 {
    padding: 40px;
  }
  .pt-xxl-5 {
    padding-top: 40px;
  }
  .pr-xxl-5 {
    padding-right: 40px;
  }
  .pb-xxl-5 {
    padding-bottom: 40px;
  }
  .pl-xxl-5 {
    padding-left: 40px;
  }
  .m-xxl-6 {
    margin: 48px;
  }
  .mt-xxl-6 {
    margin-top: 48px;
  }
  .mr-xxl-6 {
    margin-right: 48px;
  }
  .mb-xxl-6 {
    margin-bottom: 48px;
  }
  .ml-xxl-6 {
    margin-left: 48px;
  }
  .p-xxl-6 {
    padding: 48px;
  }
  .pt-xxl-6 {
    padding-top: 48px;
  }
  .pr-xxl-6 {
    padding-right: 48px;
  }
  .pb-xxl-6 {
    padding-bottom: 48px;
  }
  .pl-xxl-6 {
    padding-left: 48px;
  }
  .m-xxl-7 {
    margin: 56px;
  }
  .mt-xxl-7 {
    margin-top: 56px;
  }
  .mr-xxl-7 {
    margin-right: 56px;
  }
  .mb-xxl-7 {
    margin-bottom: 56px;
  }
  .ml-xxl-7 {
    margin-left: 56px;
  }
  .p-xxl-7 {
    padding: 56px;
  }
  .pt-xxl-7 {
    padding-top: 56px;
  }
  .pr-xxl-7 {
    padding-right: 56px;
  }
  .pb-xxl-7 {
    padding-bottom: 56px;
  }
  .pl-xxl-7 {
    padding-left: 56px;
  }
  .m-xxl-8 {
    margin: 64px;
  }
  .mt-xxl-8 {
    margin-top: 64px;
  }
  .mr-xxl-8 {
    margin-right: 64px;
  }
  .mb-xxl-8 {
    margin-bottom: 64px;
  }
  .ml-xxl-8 {
    margin-left: 64px;
  }
  .p-xxl-8 {
    padding: 64px;
  }
  .pt-xxl-8 {
    padding-top: 64px;
  }
  .pr-xxl-8 {
    padding-right: 64px;
  }
  .pb-xxl-8 {
    padding-bottom: 64px;
  }
  .pl-xxl-8 {
    padding-left: 64px;
  }
  .m-xxl-9 {
    margin: 72px;
  }
  .mt-xxl-9 {
    margin-top: 72px;
  }
  .mr-xxl-9 {
    margin-right: 72px;
  }
  .mb-xxl-9 {
    margin-bottom: 72px;
  }
  .ml-xxl-9 {
    margin-left: 72px;
  }
  .p-xxl-9 {
    padding: 72px;
  }
  .pt-xxl-9 {
    padding-top: 72px;
  }
  .pr-xxl-9 {
    padding-right: 72px;
  }
  .pb-xxl-9 {
    padding-bottom: 72px;
  }
  .pl-xxl-9 {
    padding-left: 72px;
  }
  .m-xxl-10 {
    margin: 80px;
  }
  .mt-xxl-10 {
    margin-top: 80px;
  }
  .mr-xxl-10 {
    margin-right: 80px;
  }
  .mb-xxl-10 {
    margin-bottom: 80px;
  }
  .ml-xxl-10 {
    margin-left: 80px;
  }
  .p-xxl-10 {
    padding: 80px;
  }
  .pt-xxl-10 {
    padding-top: 80px;
  }
  .pr-xxl-10 {
    padding-right: 80px;
  }
  .pb-xxl-10 {
    padding-bottom: 80px;
  }
  .pl-xxl-10 {
    padding-left: 80px;
  }
  .m-xxl-11 {
    margin: 88px;
  }
  .mt-xxl-11 {
    margin-top: 88px;
  }
  .mr-xxl-11 {
    margin-right: 88px;
  }
  .mb-xxl-11 {
    margin-bottom: 88px;
  }
  .ml-xxl-11 {
    margin-left: 88px;
  }
  .p-xxl-11 {
    padding: 88px;
  }
  .pt-xxl-11 {
    padding-top: 88px;
  }
  .pr-xxl-11 {
    padding-right: 88px;
  }
  .pb-xxl-11 {
    padding-bottom: 88px;
  }
  .pl-xxl-11 {
    padding-left: 88px;
  }
  .m-xxl-12 {
    margin: 96px;
  }
  .mt-xxl-12 {
    margin-top: 96px;
  }
  .mr-xxl-12 {
    margin-right: 96px;
  }
  .mb-xxl-12 {
    margin-bottom: 96px;
  }
  .ml-xxl-12 {
    margin-left: 96px;
  }
  .p-xxl-12 {
    padding: 96px;
  }
  .pt-xxl-12 {
    padding-top: 96px;
  }
  .pr-xxl-12 {
    padding-right: 96px;
  }
  .pb-xxl-12 {
    padding-bottom: 96px;
  }
  .pl-xxl-12 {
    padding-left: 96px;
  }
  .m-xxl-13 {
    margin: 104px;
  }
  .mt-xxl-13 {
    margin-top: 104px;
  }
  .mr-xxl-13 {
    margin-right: 104px;
  }
  .mb-xxl-13 {
    margin-bottom: 104px;
  }
  .ml-xxl-13 {
    margin-left: 104px;
  }
  .p-xxl-13 {
    padding: 104px;
  }
  .pt-xxl-13 {
    padding-top: 104px;
  }
  .pr-xxl-13 {
    padding-right: 104px;
  }
  .pb-xxl-13 {
    padding-bottom: 104px;
  }
  .pl-xxl-13 {
    padding-left: 104px;
  }
  .m-xxl-14 {
    margin: 112px;
  }
  .mt-xxl-14 {
    margin-top: 112px;
  }
  .mr-xxl-14 {
    margin-right: 112px;
  }
  .mb-xxl-14 {
    margin-bottom: 112px;
  }
  .ml-xxl-14 {
    margin-left: 112px;
  }
  .p-xxl-14 {
    padding: 112px;
  }
  .pt-xxl-14 {
    padding-top: 112px;
  }
  .pr-xxl-14 {
    padding-right: 112px;
  }
  .pb-xxl-14 {
    padding-bottom: 112px;
  }
  .pl-xxl-14 {
    padding-left: 112px;
  }
  .m-xxl-15 {
    margin: 120px;
  }
  .mt-xxl-15 {
    margin-top: 120px;
  }
  .mr-xxl-15 {
    margin-right: 120px;
  }
  .mb-xxl-15 {
    margin-bottom: 120px;
  }
  .ml-xxl-15 {
    margin-left: 120px;
  }
  .p-xxl-15 {
    padding: 120px;
  }
  .pt-xxl-15 {
    padding-top: 120px;
  }
  .pr-xxl-15 {
    padding-right: 120px;
  }
  .pb-xxl-15 {
    padding-bottom: 120px;
  }
  .pl-xxl-15 {
    padding-left: 120px;
  }
  .m-xxl-16 {
    margin: 128px;
  }
  .mt-xxl-16 {
    margin-top: 128px;
  }
  .mr-xxl-16 {
    margin-right: 128px;
  }
  .mb-xxl-16 {
    margin-bottom: 128px;
  }
  .ml-xxl-16 {
    margin-left: 128px;
  }
  .p-xxl-16 {
    padding: 128px;
  }
  .pt-xxl-16 {
    padding-top: 128px;
  }
  .pr-xxl-16 {
    padding-right: 128px;
  }
  .pb-xxl-16 {
    padding-bottom: 128px;
  }
  .pl-xxl-16 {
    padding-left: 128px;
  }
  .m-xxl-17 {
    margin: 136px;
  }
  .mt-xxl-17 {
    margin-top: 136px;
  }
  .mr-xxl-17 {
    margin-right: 136px;
  }
  .mb-xxl-17 {
    margin-bottom: 136px;
  }
  .ml-xxl-17 {
    margin-left: 136px;
  }
  .p-xxl-17 {
    padding: 136px;
  }
  .pt-xxl-17 {
    padding-top: 136px;
  }
  .pr-xxl-17 {
    padding-right: 136px;
  }
  .pb-xxl-17 {
    padding-bottom: 136px;
  }
  .pl-xxl-17 {
    padding-left: 136px;
  }
  .m-xxl-18 {
    margin: 144px;
  }
  .mt-xxl-18 {
    margin-top: 144px;
  }
  .mr-xxl-18 {
    margin-right: 144px;
  }
  .mb-xxl-18 {
    margin-bottom: 144px;
  }
  .ml-xxl-18 {
    margin-left: 144px;
  }
  .p-xxl-18 {
    padding: 144px;
  }
  .pt-xxl-18 {
    padding-top: 144px;
  }
  .pr-xxl-18 {
    padding-right: 144px;
  }
  .pb-xxl-18 {
    padding-bottom: 144px;
  }
  .pl-xxl-18 {
    padding-left: 144px;
  }
  .m-xxl-19 {
    margin: 152px;
  }
  .mt-xxl-19 {
    margin-top: 152px;
  }
  .mr-xxl-19 {
    margin-right: 152px;
  }
  .mb-xxl-19 {
    margin-bottom: 152px;
  }
  .ml-xxl-19 {
    margin-left: 152px;
  }
  .p-xxl-19 {
    padding: 152px;
  }
  .pt-xxl-19 {
    padding-top: 152px;
  }
  .pr-xxl-19 {
    padding-right: 152px;
  }
  .pb-xxl-19 {
    padding-bottom: 152px;
  }
  .pl-xxl-19 {
    padding-left: 152px;
  }
  .m-xxl-20 {
    margin: 160px;
  }
  .mt-xxl-20 {
    margin-top: 160px;
  }
  .mr-xxl-20 {
    margin-right: 160px;
  }
  .mb-xxl-20 {
    margin-bottom: 160px;
  }
  .ml-xxl-20 {
    margin-left: 160px;
  }
  .p-xxl-20 {
    padding: 160px;
  }
  .pt-xxl-20 {
    padding-top: 160px;
  }
  .pr-xxl-20 {
    padding-right: 160px;
  }
  .pb-xxl-20 {
    padding-bottom: 160px;
  }
  .pl-xxl-20 {
    padding-left: 160px;
  }
}
@media (min-width: 0px) {
  .m-xs-a {
    margin-left: auto;
    margin-right: auto;
  }
}
@media (min-width: 576px) {
  .m-sm-a {
    margin-left: auto;
    margin-right: auto;
  }
}
@media (min-width: 768px) {
  .m-md-a {
    margin-left: auto;
    margin-right: auto;
  }
}
@media (min-width: 992px) {
  .m-lg-a {
    margin-left: auto;
    margin-right: auto;
  }
}
@media (min-width: 1200px) {
  .m-xl-a {
    margin-left: auto;
    margin-right: auto;
  }
}
@media (min-width: 1400px) {
  .m-xxl-a {
    margin-left: auto;
    margin-right: auto;
  }
}
@media (min-width: 0px) {
  .rg-xs-0 {
    row-gap: 0;
  }
  .cg-xs-0 {
    column-gap: 0;
  }
  .rg-xs-1 {
    row-gap: 8px;
  }
  .cg-xs-1 {
    column-gap: 8px;
  }
  .rg-xs-2 {
    row-gap: 16px;
  }
  .cg-xs-2 {
    column-gap: 16px;
  }
  .rg-xs-3 {
    row-gap: 24px;
  }
  .cg-xs-3 {
    column-gap: 24px;
  }
  .rg-xs-4 {
    row-gap: 32px;
  }
  .cg-xs-4 {
    column-gap: 32px;
  }
  .rg-xs-5 {
    row-gap: 40px;
  }
  .cg-xs-5 {
    column-gap: 40px;
  }
  .rg-xs-6 {
    row-gap: 48px;
  }
  .cg-xs-6 {
    column-gap: 48px;
  }
  .rg-xs-7 {
    row-gap: 56px;
  }
  .cg-xs-7 {
    column-gap: 56px;
  }
  .rg-xs-8 {
    row-gap: 64px;
  }
  .cg-xs-8 {
    column-gap: 64px;
  }
  .rg-xs-9 {
    row-gap: 72px;
  }
  .cg-xs-9 {
    column-gap: 72px;
  }
  .rg-xs-10 {
    row-gap: 80px;
  }
  .cg-xs-10 {
    column-gap: 80px;
  }
  .rg-xs-11 {
    row-gap: 88px;
  }
  .cg-xs-11 {
    column-gap: 88px;
  }
  .rg-xs-12 {
    row-gap: 96px;
  }
  .cg-xs-12 {
    column-gap: 96px;
  }
  .rg-xs-13 {
    row-gap: 104px;
  }
  .cg-xs-13 {
    column-gap: 104px;
  }
  .rg-xs-14 {
    row-gap: 112px;
  }
  .cg-xs-14 {
    column-gap: 112px;
  }
  .rg-xs-15 {
    row-gap: 120px;
  }
  .cg-xs-15 {
    column-gap: 120px;
  }
  .rg-xs-16 {
    row-gap: 128px;
  }
  .cg-xs-16 {
    column-gap: 128px;
  }
  .rg-xs-17 {
    row-gap: 136px;
  }
  .cg-xs-17 {
    column-gap: 136px;
  }
  .rg-xs-18 {
    row-gap: 144px;
  }
  .cg-xs-18 {
    column-gap: 144px;
  }
  .rg-xs-19 {
    row-gap: 152px;
  }
  .cg-xs-19 {
    column-gap: 152px;
  }
  .rg-xs-20 {
    row-gap: 160px;
  }
  .cg-xs-20 {
    column-gap: 160px;
  }
}
@media (min-width: 576px) {
  .rg-sm-0 {
    row-gap: 0;
  }
  .cg-sm-0 {
    column-gap: 0;
  }
  .rg-sm-1 {
    row-gap: 8px;
  }
  .cg-sm-1 {
    column-gap: 8px;
  }
  .rg-sm-2 {
    row-gap: 16px;
  }
  .cg-sm-2 {
    column-gap: 16px;
  }
  .rg-sm-3 {
    row-gap: 24px;
  }
  .cg-sm-3 {
    column-gap: 24px;
  }
  .rg-sm-4 {
    row-gap: 32px;
  }
  .cg-sm-4 {
    column-gap: 32px;
  }
  .rg-sm-5 {
    row-gap: 40px;
  }
  .cg-sm-5 {
    column-gap: 40px;
  }
  .rg-sm-6 {
    row-gap: 48px;
  }
  .cg-sm-6 {
    column-gap: 48px;
  }
  .rg-sm-7 {
    row-gap: 56px;
  }
  .cg-sm-7 {
    column-gap: 56px;
  }
  .rg-sm-8 {
    row-gap: 64px;
  }
  .cg-sm-8 {
    column-gap: 64px;
  }
  .rg-sm-9 {
    row-gap: 72px;
  }
  .cg-sm-9 {
    column-gap: 72px;
  }
  .rg-sm-10 {
    row-gap: 80px;
  }
  .cg-sm-10 {
    column-gap: 80px;
  }
  .rg-sm-11 {
    row-gap: 88px;
  }
  .cg-sm-11 {
    column-gap: 88px;
  }
  .rg-sm-12 {
    row-gap: 96px;
  }
  .cg-sm-12 {
    column-gap: 96px;
  }
  .rg-sm-13 {
    row-gap: 104px;
  }
  .cg-sm-13 {
    column-gap: 104px;
  }
  .rg-sm-14 {
    row-gap: 112px;
  }
  .cg-sm-14 {
    column-gap: 112px;
  }
  .rg-sm-15 {
    row-gap: 120px;
  }
  .cg-sm-15 {
    column-gap: 120px;
  }
  .rg-sm-16 {
    row-gap: 128px;
  }
  .cg-sm-16 {
    column-gap: 128px;
  }
  .rg-sm-17 {
    row-gap: 136px;
  }
  .cg-sm-17 {
    column-gap: 136px;
  }
  .rg-sm-18 {
    row-gap: 144px;
  }
  .cg-sm-18 {
    column-gap: 144px;
  }
  .rg-sm-19 {
    row-gap: 152px;
  }
  .cg-sm-19 {
    column-gap: 152px;
  }
  .rg-sm-20 {
    row-gap: 160px;
  }
  .cg-sm-20 {
    column-gap: 160px;
  }
}
@media (min-width: 768px) {
  .rg-md-0 {
    row-gap: 0;
  }
  .cg-md-0 {
    column-gap: 0;
  }
  .rg-md-1 {
    row-gap: 8px;
  }
  .cg-md-1 {
    column-gap: 8px;
  }
  .rg-md-2 {
    row-gap: 16px;
  }
  .cg-md-2 {
    column-gap: 16px;
  }
  .rg-md-3 {
    row-gap: 24px;
  }
  .cg-md-3 {
    column-gap: 24px;
  }
  .rg-md-4 {
    row-gap: 32px;
  }
  .cg-md-4 {
    column-gap: 32px;
  }
  .rg-md-5 {
    row-gap: 40px;
  }
  .cg-md-5 {
    column-gap: 40px;
  }
  .rg-md-6 {
    row-gap: 48px;
  }
  .cg-md-6 {
    column-gap: 48px;
  }
  .rg-md-7 {
    row-gap: 56px;
  }
  .cg-md-7 {
    column-gap: 56px;
  }
  .rg-md-8 {
    row-gap: 64px;
  }
  .cg-md-8 {
    column-gap: 64px;
  }
  .rg-md-9 {
    row-gap: 72px;
  }
  .cg-md-9 {
    column-gap: 72px;
  }
  .rg-md-10 {
    row-gap: 80px;
  }
  .cg-md-10 {
    column-gap: 80px;
  }
  .rg-md-11 {
    row-gap: 88px;
  }
  .cg-md-11 {
    column-gap: 88px;
  }
  .rg-md-12 {
    row-gap: 96px;
  }
  .cg-md-12 {
    column-gap: 96px;
  }
  .rg-md-13 {
    row-gap: 104px;
  }
  .cg-md-13 {
    column-gap: 104px;
  }
  .rg-md-14 {
    row-gap: 112px;
  }
  .cg-md-14 {
    column-gap: 112px;
  }
  .rg-md-15 {
    row-gap: 120px;
  }
  .cg-md-15 {
    column-gap: 120px;
  }
  .rg-md-16 {
    row-gap: 128px;
  }
  .cg-md-16 {
    column-gap: 128px;
  }
  .rg-md-17 {
    row-gap: 136px;
  }
  .cg-md-17 {
    column-gap: 136px;
  }
  .rg-md-18 {
    row-gap: 144px;
  }
  .cg-md-18 {
    column-gap: 144px;
  }
  .rg-md-19 {
    row-gap: 152px;
  }
  .cg-md-19 {
    column-gap: 152px;
  }
  .rg-md-20 {
    row-gap: 160px;
  }
  .cg-md-20 {
    column-gap: 160px;
  }
}
@media (min-width: 992px) {
  .rg-lg-0 {
    row-gap: 0;
  }
  .cg-lg-0 {
    column-gap: 0;
  }
  .rg-lg-1 {
    row-gap: 8px;
  }
  .cg-lg-1 {
    column-gap: 8px;
  }
  .rg-lg-2 {
    row-gap: 16px;
  }
  .cg-lg-2 {
    column-gap: 16px;
  }
  .rg-lg-3 {
    row-gap: 24px;
  }
  .cg-lg-3 {
    column-gap: 24px;
  }
  .rg-lg-4 {
    row-gap: 32px;
  }
  .cg-lg-4 {
    column-gap: 32px;
  }
  .rg-lg-5 {
    row-gap: 40px;
  }
  .cg-lg-5 {
    column-gap: 40px;
  }
  .rg-lg-6 {
    row-gap: 48px;
  }
  .cg-lg-6 {
    column-gap: 48px;
  }
  .rg-lg-7 {
    row-gap: 56px;
  }
  .cg-lg-7 {
    column-gap: 56px;
  }
  .rg-lg-8 {
    row-gap: 64px;
  }
  .cg-lg-8 {
    column-gap: 64px;
  }
  .rg-lg-9 {
    row-gap: 72px;
  }
  .cg-lg-9 {
    column-gap: 72px;
  }
  .rg-lg-10 {
    row-gap: 80px;
  }
  .cg-lg-10 {
    column-gap: 80px;
  }
  .rg-lg-11 {
    row-gap: 88px;
  }
  .cg-lg-11 {
    column-gap: 88px;
  }
  .rg-lg-12 {
    row-gap: 96px;
  }
  .cg-lg-12 {
    column-gap: 96px;
  }
  .rg-lg-13 {
    row-gap: 104px;
  }
  .cg-lg-13 {
    column-gap: 104px;
  }
  .rg-lg-14 {
    row-gap: 112px;
  }
  .cg-lg-14 {
    column-gap: 112px;
  }
  .rg-lg-15 {
    row-gap: 120px;
  }
  .cg-lg-15 {
    column-gap: 120px;
  }
  .rg-lg-16 {
    row-gap: 128px;
  }
  .cg-lg-16 {
    column-gap: 128px;
  }
  .rg-lg-17 {
    row-gap: 136px;
  }
  .cg-lg-17 {
    column-gap: 136px;
  }
  .rg-lg-18 {
    row-gap: 144px;
  }
  .cg-lg-18 {
    column-gap: 144px;
  }
  .rg-lg-19 {
    row-gap: 152px;
  }
  .cg-lg-19 {
    column-gap: 152px;
  }
  .rg-lg-20 {
    row-gap: 160px;
  }
  .cg-lg-20 {
    column-gap: 160px;
  }
}
@media (min-width: 1200px) {
  .rg-xl-0 {
    row-gap: 0;
  }
  .cg-xl-0 {
    column-gap: 0;
  }
  .rg-xl-1 {
    row-gap: 8px;
  }
  .cg-xl-1 {
    column-gap: 8px;
  }
  .rg-xl-2 {
    row-gap: 16px;
  }
  .cg-xl-2 {
    column-gap: 16px;
  }
  .rg-xl-3 {
    row-gap: 24px;
  }
  .cg-xl-3 {
    column-gap: 24px;
  }
  .rg-xl-4 {
    row-gap: 32px;
  }
  .cg-xl-4 {
    column-gap: 32px;
  }
  .rg-xl-5 {
    row-gap: 40px;
  }
  .cg-xl-5 {
    column-gap: 40px;
  }
  .rg-xl-6 {
    row-gap: 48px;
  }
  .cg-xl-6 {
    column-gap: 48px;
  }
  .rg-xl-7 {
    row-gap: 56px;
  }
  .cg-xl-7 {
    column-gap: 56px;
  }
  .rg-xl-8 {
    row-gap: 64px;
  }
  .cg-xl-8 {
    column-gap: 64px;
  }
  .rg-xl-9 {
    row-gap: 72px;
  }
  .cg-xl-9 {
    column-gap: 72px;
  }
  .rg-xl-10 {
    row-gap: 80px;
  }
  .cg-xl-10 {
    column-gap: 80px;
  }
  .rg-xl-11 {
    row-gap: 88px;
  }
  .cg-xl-11 {
    column-gap: 88px;
  }
  .rg-xl-12 {
    row-gap: 96px;
  }
  .cg-xl-12 {
    column-gap: 96px;
  }
  .rg-xl-13 {
    row-gap: 104px;
  }
  .cg-xl-13 {
    column-gap: 104px;
  }
  .rg-xl-14 {
    row-gap: 112px;
  }
  .cg-xl-14 {
    column-gap: 112px;
  }
  .rg-xl-15 {
    row-gap: 120px;
  }
  .cg-xl-15 {
    column-gap: 120px;
  }
  .rg-xl-16 {
    row-gap: 128px;
  }
  .cg-xl-16 {
    column-gap: 128px;
  }
  .rg-xl-17 {
    row-gap: 136px;
  }
  .cg-xl-17 {
    column-gap: 136px;
  }
  .rg-xl-18 {
    row-gap: 144px;
  }
  .cg-xl-18 {
    column-gap: 144px;
  }
  .rg-xl-19 {
    row-gap: 152px;
  }
  .cg-xl-19 {
    column-gap: 152px;
  }
  .rg-xl-20 {
    row-gap: 160px;
  }
  .cg-xl-20 {
    column-gap: 160px;
  }
}
@media (min-width: 1400px) {
  .rg-xxl-0 {
    row-gap: 0;
  }
  .cg-xxl-0 {
    column-gap: 0;
  }
  .rg-xxl-1 {
    row-gap: 8px;
  }
  .cg-xxl-1 {
    column-gap: 8px;
  }
  .rg-xxl-2 {
    row-gap: 16px;
  }
  .cg-xxl-2 {
    column-gap: 16px;
  }
  .rg-xxl-3 {
    row-gap: 24px;
  }
  .cg-xxl-3 {
    column-gap: 24px;
  }
  .rg-xxl-4 {
    row-gap: 32px;
  }
  .cg-xxl-4 {
    column-gap: 32px;
  }
  .rg-xxl-5 {
    row-gap: 40px;
  }
  .cg-xxl-5 {
    column-gap: 40px;
  }
  .rg-xxl-6 {
    row-gap: 48px;
  }
  .cg-xxl-6 {
    column-gap: 48px;
  }
  .rg-xxl-7 {
    row-gap: 56px;
  }
  .cg-xxl-7 {
    column-gap: 56px;
  }
  .rg-xxl-8 {
    row-gap: 64px;
  }
  .cg-xxl-8 {
    column-gap: 64px;
  }
  .rg-xxl-9 {
    row-gap: 72px;
  }
  .cg-xxl-9 {
    column-gap: 72px;
  }
  .rg-xxl-10 {
    row-gap: 80px;
  }
  .cg-xxl-10 {
    column-gap: 80px;
  }
  .rg-xxl-11 {
    row-gap: 88px;
  }
  .cg-xxl-11 {
    column-gap: 88px;
  }
  .rg-xxl-12 {
    row-gap: 96px;
  }
  .cg-xxl-12 {
    column-gap: 96px;
  }
  .rg-xxl-13 {
    row-gap: 104px;
  }
  .cg-xxl-13 {
    column-gap: 104px;
  }
  .rg-xxl-14 {
    row-gap: 112px;
  }
  .cg-xxl-14 {
    column-gap: 112px;
  }
  .rg-xxl-15 {
    row-gap: 120px;
  }
  .cg-xxl-15 {
    column-gap: 120px;
  }
  .rg-xxl-16 {
    row-gap: 128px;
  }
  .cg-xxl-16 {
    column-gap: 128px;
  }
  .rg-xxl-17 {
    row-gap: 136px;
  }
  .cg-xxl-17 {
    column-gap: 136px;
  }
  .rg-xxl-18 {
    row-gap: 144px;
  }
  .cg-xxl-18 {
    column-gap: 144px;
  }
  .rg-xxl-19 {
    row-gap: 152px;
  }
  .cg-xxl-19 {
    column-gap: 152px;
  }
  .rg-xxl-20 {
    row-gap: 160px;
  }
  .cg-xxl-20 {
    column-gap: 160px;
  }
}
@media (min-width: 0px) {
  .or-xs-1 {
    order: 1;
  }
  .or-xs-2 {
    order: 2;
  }
  .or-xs-3 {
    order: 3;
  }
  .or-xs-4 {
    order: 4;
  }
  .or-xs-5 {
    order: 5;
  }
  .or-xs-6 {
    order: 6;
  }
  .or-xs-7 {
    order: 7;
  }
  .or-xs-8 {
    order: 8;
  }
  .or-xs-9 {
    order: 9;
  }
  .or-xs-10 {
    order: 10;
  }
  .or-xs-11 {
    order: 11;
  }
  .or-xs-12 {
    order: 12;
  }
  .or-xs-13 {
    order: 13;
  }
  .or-xs-14 {
    order: 14;
  }
  .or-xs-15 {
    order: 15;
  }
  .or-xs-16 {
    order: 16;
  }
  .or-xs-17 {
    order: 17;
  }
  .or-xs-18 {
    order: 18;
  }
  .or-xs-19 {
    order: 19;
  }
  .or-xs-20 {
    order: 20;
  }
}
@media (min-width: 576px) {
  .or-sm-1 {
    order: 1;
  }
  .or-sm-2 {
    order: 2;
  }
  .or-sm-3 {
    order: 3;
  }
  .or-sm-4 {
    order: 4;
  }
  .or-sm-5 {
    order: 5;
  }
  .or-sm-6 {
    order: 6;
  }
  .or-sm-7 {
    order: 7;
  }
  .or-sm-8 {
    order: 8;
  }
  .or-sm-9 {
    order: 9;
  }
  .or-sm-10 {
    order: 10;
  }
  .or-sm-11 {
    order: 11;
  }
  .or-sm-12 {
    order: 12;
  }
  .or-sm-13 {
    order: 13;
  }
  .or-sm-14 {
    order: 14;
  }
  .or-sm-15 {
    order: 15;
  }
  .or-sm-16 {
    order: 16;
  }
  .or-sm-17 {
    order: 17;
  }
  .or-sm-18 {
    order: 18;
  }
  .or-sm-19 {
    order: 19;
  }
  .or-sm-20 {
    order: 20;
  }
}
@media (min-width: 768px) {
  .or-md-1 {
    order: 1;
  }
  .or-md-2 {
    order: 2;
  }
  .or-md-3 {
    order: 3;
  }
  .or-md-4 {
    order: 4;
  }
  .or-md-5 {
    order: 5;
  }
  .or-md-6 {
    order: 6;
  }
  .or-md-7 {
    order: 7;
  }
  .or-md-8 {
    order: 8;
  }
  .or-md-9 {
    order: 9;
  }
  .or-md-10 {
    order: 10;
  }
  .or-md-11 {
    order: 11;
  }
  .or-md-12 {
    order: 12;
  }
  .or-md-13 {
    order: 13;
  }
  .or-md-14 {
    order: 14;
  }
  .or-md-15 {
    order: 15;
  }
  .or-md-16 {
    order: 16;
  }
  .or-md-17 {
    order: 17;
  }
  .or-md-18 {
    order: 18;
  }
  .or-md-19 {
    order: 19;
  }
  .or-md-20 {
    order: 20;
  }
}
@media (min-width: 992px) {
  .or-lg-1 {
    order: 1;
  }
  .or-lg-2 {
    order: 2;
  }
  .or-lg-3 {
    order: 3;
  }
  .or-lg-4 {
    order: 4;
  }
  .or-lg-5 {
    order: 5;
  }
  .or-lg-6 {
    order: 6;
  }
  .or-lg-7 {
    order: 7;
  }
  .or-lg-8 {
    order: 8;
  }
  .or-lg-9 {
    order: 9;
  }
  .or-lg-10 {
    order: 10;
  }
  .or-lg-11 {
    order: 11;
  }
  .or-lg-12 {
    order: 12;
  }
  .or-lg-13 {
    order: 13;
  }
  .or-lg-14 {
    order: 14;
  }
  .or-lg-15 {
    order: 15;
  }
  .or-lg-16 {
    order: 16;
  }
  .or-lg-17 {
    order: 17;
  }
  .or-lg-18 {
    order: 18;
  }
  .or-lg-19 {
    order: 19;
  }
  .or-lg-20 {
    order: 20;
  }
}
@media (min-width: 1200px) {
  .or-xl-1 {
    order: 1;
  }
  .or-xl-2 {
    order: 2;
  }
  .or-xl-3 {
    order: 3;
  }
  .or-xl-4 {
    order: 4;
  }
  .or-xl-5 {
    order: 5;
  }
  .or-xl-6 {
    order: 6;
  }
  .or-xl-7 {
    order: 7;
  }
  .or-xl-8 {
    order: 8;
  }
  .or-xl-9 {
    order: 9;
  }
  .or-xl-10 {
    order: 10;
  }
  .or-xl-11 {
    order: 11;
  }
  .or-xl-12 {
    order: 12;
  }
  .or-xl-13 {
    order: 13;
  }
  .or-xl-14 {
    order: 14;
  }
  .or-xl-15 {
    order: 15;
  }
  .or-xl-16 {
    order: 16;
  }
  .or-xl-17 {
    order: 17;
  }
  .or-xl-18 {
    order: 18;
  }
  .or-xl-19 {
    order: 19;
  }
  .or-xl-20 {
    order: 20;
  }
}
@media (min-width: 1400px) {
  .or-xxl-1 {
    order: 1;
  }
  .or-xxl-2 {
    order: 2;
  }
  .or-xxl-3 {
    order: 3;
  }
  .or-xxl-4 {
    order: 4;
  }
  .or-xxl-5 {
    order: 5;
  }
  .or-xxl-6 {
    order: 6;
  }
  .or-xxl-7 {
    order: 7;
  }
  .or-xxl-8 {
    order: 8;
  }
  .or-xxl-9 {
    order: 9;
  }
  .or-xxl-10 {
    order: 10;
  }
  .or-xxl-11 {
    order: 11;
  }
  .or-xxl-12 {
    order: 12;
  }
  .or-xxl-13 {
    order: 13;
  }
  .or-xxl-14 {
    order: 14;
  }
  .or-xxl-15 {
    order: 15;
  }
  .or-xxl-16 {
    order: 16;
  }
  .or-xxl-17 {
    order: 17;
  }
  .or-xxl-18 {
    order: 18;
  }
  .or-xxl-19 {
    order: 19;
  }
  .or-xxl-20 {
    order: 20;
  }
}

@use "@scss/global.scss" as *;

.ribbon {
  cursor: inherit;
  pointer-events: none;

  position: absolute;

  width: 100%;
  height: 0px;

  left: 0px;
  top: 0px;
  transform: translate(-50%, 0px);

  .content {
    cursor: inherit;

    box-shadow: 0px 0px 29px 0px rgba(0, 0, 0, 0.19), 0px 0px 16.984px 0px rgba(0, 0, 0, 0.14),
      0px 0px 9.237px 0px rgba(0, 0, 0, 0.12), 0px 0px 4.713px 0px rgba(0, 0, 0, 0.09),
      0px 0px 2.363px 0px rgba(0, 0, 0, 0.07), 0px 0px 1.141px 0px rgba(0, 0, 0, 0.05);

    width: 100%;
    height: 64px;

    transform-origin: center top;
    rotate: -45deg;

    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    padding: 8px 0px;
  }
}

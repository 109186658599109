@use "@scss/mixins.scss" as *;
@use "@scss/variables.scss" as *;

//TYPOGRAPHY
h1.d1 {
  @include d1;
  @include mq-max($lg) {
    @include md1;
  }
}

h1.d2 {
  @include d2;
  @include mq-max($lg) {
    @include md2;
  }
}

h1,
.h1 {
  @include h1;

  // @include mq-max($lg) {
  //   @include mh1;
  // }
}

h2,
.h2 {
  @include h2;

  // @include mq-max($lg) {
  //   @include mh2;
  // }
}

h3,
.h3 {
  @include h3;

  // @include mq-max($lg) {
  //   @include mh3;
  // }
}

h4,
.h4 {
  @include h4;
  // @include mq-max($lg) {
  //   @include mh4;
  // }
}

h5,
.h5 {
  @include h5;
  // @include mq-max($lg) {
  //   @include mh5;
  // }
}

.p1 {
  @include p1;
}

.p2 {
  @include p2;

  // @include mq-max($sm) {
  //   @include mp2;
  // }
}

.p3 {
  @include p3;

  // @include mq-max($sm) {
  //   @include mp3;
  // }
}
//TYPOGRAPHY

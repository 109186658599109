@use "@scss/global.scss" as *;

.navbar {
  height: $navbar-height-desktop;
  width: 100%;
  max-width: $max-content-width;

  position: fixed;
  left: 50%;
  transform: translateX(-50%);

  top: $size-3;
  @include basePadding-x;

  &.mobile {
    height: $navbar-height-mobile;
    padding: 0px;
    top: 0px;
    border-radius: 0px;

    .nav-inner {
      border-radius: 0px;

      .nav-content {
        padding: 0px 16px;
      }
    }
  }

  .nav-inner {
    height: inherit;
    width: 100%;

    background-color: rgba(0, 0, 0, 0.4);
    backdrop-filter: blur(10px);

    border-radius: $size-2;

    .nav-content {
      padding: 0px $size-6;
    }
  }
}

@use "@scss/global.scss" as *;

.limited-size-wrapper {
  width: 100%;
  max-width: $max-content-width;
  min-height: 100%;
  height: max-content;
  margin: 0 auto;
  padding: 0px 16px;
}

@use "@scss/global.scss" as *;

.hero {
  background-image: url("../../../../../../public/images/hero/bg.png");
  // min-height: 100svh;
  min-height: 100vh;
  height: 1577px;
  width: 100%;

  // padding: 0px 16px;
  padding-bottom: 16px;
  // @include basePadding;
  background-position: center top;
  // background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  overflow: hidden;

  @include mq-max($lg) {
    height: max-content;
  }

  .parallax-wrapper {
    position: absolute;
    z-index: 0;
    top: 0px;
    // left: 50%;

    // transform: translateX(-50%);
    // -webkit-transform: translateX(-50%);
    // -webkit-transform: translate3d(-50%, 0, 0);
    transform-style: preserve-3d !important;
    -webkit-transform-style: preserve-3d !important;

    width: 1920px;
    // max-width: 1920px;
    height: 100%;

    .plan {
      &-1,
      &-2,
      &-3 {
        will-change: transform;
        position: absolute;
        width: 100%;
      }
      &-1 {
        z-index: 6;
        top: 15%;
      }
      &-2 {
        z-index: 5;
      }
      &-3 {
        z-index: 4;
      }
    }

    // @include mq-max($lg) {
    //   left: 50%;
    //   top: 0px;
    //   transform: translate(-50%, 0%);
    //   right: unset;
    //   z-index: -1;
    //   width: 415px;
    //   height: 445px;
    // }
  }
}

@use "@scss/global.scss" as *;

.footer-bg {
}

.footer {
  .bottom-1 {
    height: 110px;
    width: 100%;
  }
}

@use "@scss/global.scss" as *;

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

* {
  margin: 0px;
  margin-top: 0px;
  margin-left: 0px;
  margin-bottom: 0px;
  margin-right: 0px;
  padding: 0px;
  padding-top: 0px;
  padding-left: 0px;
  padding-bottom: 0px;
  padding-right: 0px;
  box-sizing: border-box;
  font-family: "Inter", sans-serif !important;
}

html {
  scroll-behavior: smooth !important;
  background: $white-black-2;
  color: $white-black-12;
  -webkit-font-smoothing: antialiased;
  overflow-x: hidden;
}

body {
  position: relative;
}

html,
body,
#root,
section {
  height: max-content;
  width: 100%;
}

hr {
  margin: 0px;
  padding: 0px;
}

a {
  color: unset;
  text-decoration: none;
  @include flex-center();
  min-height: 40px;
  min-width: 40px;

  > * {
    height: 100%;
  }
}

button {
  border: none;
  background-color: transparent;
  cursor: pointer;
}

input {
  border: none;
  outline: none;
}

@use "@scss/global.scss" as *;

.app-layout {
  width: 100%;
  height: max-content;
  position: relative;

  .navbar {
    z-index: 10;
  }

  .footer {
    z-index: 10;
  }
}

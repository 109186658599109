@use "@scss/global.scss" as *;

.open-sea {
  &-btn {
    transition: filter 0.2s ease-in-out;
    // box-shadow: 0px 114.75px 91.8px 0px rgba(0, 0, 0, 0.07), 0px 74.375px 53.763px 0px rgba(0, 0, 0, 0.05),
    //   0px 44.2px 29.24px 0px rgba(0, 0, 0, 0.04), 0px 22.95px 14.918px 0px rgba(0, 0, 0, 0.04),
    //   0px 9.35px 7.48px 0px rgba(0, 0, 0, 0.03), 0px 2.125px 3.613px 0px rgba(0, 0, 0, 0.02);

    &:hover {
      filter: brightness(1.1);
    }
  }

  .wolf {
    border-radius: 15px;
    box-shadow: 0px 43.512px 34.809px 0px rgba(0, 0, 0, 0.07), 0px 28.202px 20.386px 0px rgba(0, 0, 0, 0.05),
      0px 16.76px 11.087px 0px rgba(0, 0, 0, 0.04), 0px 8.702px 5.657px 0px rgba(0, 0, 0, 0.04),
      0px 3.545px 2.836px 0px rgba(0, 0, 0, 0.03), 0px 0.806px 1.37px 0px rgba(0, 0, 0, 0.02);
    width: 158px;
    aspect-ratio: 1/1;
  }
}

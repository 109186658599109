@use "./src/scss/global.scss" as *;

.time-part {
  width: 166px !important;
  position: relative;

  h1 {
    padding: 32px 16px;
    background-color: white;
    font-size: 125px;
    // line-height: 158px;
    align-items: center;
  }

  .part-of-time-name {
  }
  @include mq-max($lg) {
    width: inherit !important;

    h1 {
      line-height: 72px;
      padding: 0px 12px;
    }
  }
}

@keyframes slide-down {
  0% {
    // translate: 0% -100%;
    transform: translateY(-100%);
  }
  100% {
    // translate: 0% 0%;
    transform: translateY(0%);
  }
}

@keyframes slide-up {
  0% {
    // translate: 0% 0%;
    transform: translateY(0%);
  }
  100% {
    // translate: 0% -100%;
    transform: translateY(-100%);
  }
}

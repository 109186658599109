@use "@scss/global.scss" as *;

.fugazi-of-wallstreet {
  width: 100%;

  .mobile {
    height: 640px;
    position: relative;
    left: 50%;
    translate: -50%;
    width: unset;
  }
}
